<div class="container pt-75" [dir]="isRtl === 'Y' ? 'rtl' : 'ltr'">
    <div class="contact-us-offices">
        <div class="section-title section-title-main">
            <h2>{{offices}}</h2>
        </div>
        <div class="region" *ngFor="let region of getRegions()">
            <div class="section-title region-title">
                <h4><span class="title">{{ region }}</span></h4>
            </div>
            <div class="region-markers" *ngIf="popup && popup[region]">
                <div class="contactForContactUs col-xl-4 col-lg-6 col-sm-6 col-md-6" *ngFor="let marker of popup[region].markers">
                    <div class="contactDetails">
                        <div class="country">
                            <img class="flag" [src]="marker.flag" alt="Flag" />
                            <h6>&nbsp;{{ marker.title }}</h6>
                        </div>
                        <p>
                            <strong>{{ add_hd }}</strong> {{ marker.address }}
                        </p>
                        <p class="phone">
                            <strong>{{ phone_hd }}</strong> {{ marker.phone }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>