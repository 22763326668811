<div class="container pt-75" [dir]="isRtl === 'Y' ? 'rtl' : 'ltr'">
    <div class="contact-us-offices">
        <div class="section-title section-title-main">
            <h2>{{cust?.cust_hd}}</h2>
        </div>
        <p class="para">{{cust?.cust_p}}</p>
        <div class="region">
            <div class="region-markers" *ngIf="cust">
                <div class="contactForContactUs col-xl-4 col-lg-6 col-sm-6 col-md-6" *ngFor="let marker of cust.cust">
                    <div class="contactDetails">
                        <div class="country">
                            <h6>&nbsp;{{ marker.type }}</h6>
                        </div>
                        <p>
                            <strong>{{ cust.email_hd }}</strong> {{ marker.contact }}
                        </p>
                        <p class="phone">
                            <strong>{{ cust.contact_hd }}</strong> {{ marker.email }}
                        </p>
                    </div>
                </div>
                <div class="options col-xl-4 col-lg-6 col-sm-6 col-md-6">
                    <a href="ticket_link" class="default-btn">{{ticket_hd}}</a>
                </div>
            </div>
        </div>
    </div>
</div>