import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MultilingualService } from 'src/app/services/multilingual.service';

@Component({
  selector: 'app-about-modern-page',
  templateUrl: './about-modern-page.component.html',
  styleUrls: ['./about-modern-page.component.scss']
})
export class AboutModernPageComponent implements OnInit {
  page_id: string="about";
  section_id: string="p1";
  about: any={}
  isRtl: string="N";

  @ViewChild('footerComponent') footerComponent!: ElementRef;

  constructor(private multilingualService: MultilingualService) { }

  ngOnInit(): void {
    this.multilingualService.getIsRtl().subscribe(isRtl => {
      this.isRtl = isRtl;
    });
    this.multilingualService.getPageData(this.page_id, this.section_id).subscribe(
      (response)=>{
        this.about=response.data[0].page_data[0];
        // console.log('About Data:', this.about);
      },
      (error)=>{
        console.error('Error fetching about data', error);
      })
  }

scrollToFooterComponent() {
    const footerComponent = document.getElementById('footerProductDetails');
    if (footerComponent) {
      footerComponent.scrollIntoView({ 
        behavior: 'smooth'});
    }
  }

}
