import { Component, OnInit } from "@angular/core";
import { Router, NavigationCancel, NavigationEnd } from "@angular/router";
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from "@angular/common";
import { filter } from "rxjs/operators";
import { MultilingualService } from "./services/multilingual.service";
import { Title } from "@angular/platform-browser";
import { map } from "rxjs/operators";

declare let $: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [
    Location,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
  ],
})
export class AppComponent {
  title: any;
  location: any;
  routerSubscription: any;
  isLoading: boolean = true;

  constructor(
    private router: Router,
    private multilingualService: MultilingualService,
    private titleService: Title
  ) {}

  ngOnInit() {
    const pageTitle = 'SAMASA';
    this.titleService.setTitle(pageTitle);
    // this.recallJsFuntions();

    // Fixed call constraint
    this.multilingualService.fetchUserDetailsFromAPI();

    // Hard coded for dev
    // this.multilingualService.setHardCodedUserDetails();

    // this.router.events
    //   .pipe(
    //     filter((event) => event instanceof NavigationEnd),
    //     map(() => ({
    //       state: this.router.routerState,
    //       parent: this.router.routerState.root,
    //     }))
    //   )
    //   .subscribe((event) => {
    //     let title = this.getTitle(event.state, event.parent);
    //     if (title) {
    //       this.titleService.setTitle(title);
    //     }
    //   });

    this.multilingualService.isLoading$.subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
  }

  recallJsFuntions() {
    this.routerSubscription = this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationEnd || event instanceof NavigationCancel
        )
      )
      .subscribe((event) => {
        this.location = this.router.url;
        if (!(event instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0);
      });
  }

  getTitle(state: any, parent: any): string {
    let data: any = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(...this.getTitle(state, state.firstChild(parent)));
    }

    return data.join("");
  }
}
