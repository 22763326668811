import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-contact-us-partners',
  templateUrl: './contact-us-partners.component.html',
  styleUrls: ['./contact-us-partners.component.scss']
})
export class ContactUsPartnersComponent {
  @Input() partners_hd: string="";
  @Input() partners: any[]=[];
  @Input() add_hd: string="";
  @Input() phone_hd: string="";
  @Input() isRtl: string="";
}
