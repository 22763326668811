import { Component, Input, OnInit, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-details-page-module",
  templateUrl: "./details-page-module.component.html",
  styleUrl: "./details-page-module.component.scss",
})
export class DetailsPageModuleComponent implements OnInit {
  @Input() mod_hd: string = "";
  @Input() defaultImageM: string = "";
  @Input() btn1: string = "";
  @Input() btn2: string = "";
  @Input() mod: any[] = [];
  @Input() isRtl: string = "N";

  ngOnInit() {}

  // ngOnChanges(changes: SimpleChanges) {
  //   if (changes.mod && this.mod.length > 0) {
  //     this.defaultImageM = this.mod[0].defaultM_img;
  //   }
  // }

  currentImage: string = "";
  showImage(item: any) {
    this.currentImage = item.image;
  }

  scrollTo(elementId: string): void {
    const element = document.querySelector(elementId);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }
}
