import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-homenine-features',
  templateUrl: './homenine-features.component.html',
  styleUrls: ['./homenine-features.component.scss']
})
export class HomenineFeaturesComponent implements OnInit {
  @Input() sec3_l1: string = '';
  @Input() sec3: any[] = [];
  @Input() sec3_desc: string = '';
  @Input() btn1: string = '';
  @Input() btn2: string = '';
  @Input() isRtl: string = 'N'; // Default to 'N'

  @ViewChild('footerComponent') footerComponent!: ElementRef;

  cconstructor() { }

  ngOnInit(): void {
  }

  scrollToFooterComponent() {
    const footerComponent = document.getElementById('footerProductDetails');
    if (footerComponent) {
      footerComponent.scrollIntoView({ 
        behavior: 'smooth'});
    }
  }
}
