<div class="app-ever-area pb-100" [dir]="isRtl === 'Y' ? 'rtl' : 'ltr'" *ngIf="sec3 && sec3.length > 0">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="app-ever-content with-left-zero">
                    <h1 class="pb-3" *ngIf="sec3_l1">{{sec3_l1}}</h1>
                    <!-- <p>{{sec3_desc}}</p> -->
                    <ul class="list">
                        <li *ngIf="sec3[0]?.sec3_key1">
                            <div class="icon bg3 rounded-circle">
                                <i class="ri-building-line"></i>
                            </div>
                            <h3>{{sec3[0]?.sec3_key1}}</h3>
                            <p>{{sec3[0]?.sec3_pnl1}}</p>
                        </li>
                        <li *ngIf="sec3[0]?.sec3_key2">
                            <div class="icon rounded-circle">
                                <i class="ri-lightbulb-flash-line"></i>
                            </div>
                            <h3>{{sec3[0]?.sec3_key2}}</h3>
                            <p>{{sec3[0]?.sec3_pnl2}}</p>
                        </li>
                        <li *ngIf="sec3[0]?.sec3_key3">
                            <div class="icon bg3 rounded-circle">
                                <i class="ri-equalizer-line"></i>
                            </div>
                            <h3>{{sec3[0]?.sec3_key3}}</h3>
                            <p>{{sec3[0]?.sec3_pnl3}}</p>
                        </li>
                        <li *ngIf="sec3[0]?.sec3_key4">
                            <div class="icon rounded-circle">
                                <i class="ri-switch-line"></i>
                            </div>
                            <h3>{{sec3[0]?.sec3_key4}}</h3>
                            <p>{{sec3[0]?.sec3_pnl4}}</p>
                        </li>
                    </ul>
                    <div class="btn-box">
                        <a routerLink="/demo-request" class="default-btn">{{btn1}}</a>
                        <a (click)="scrollToFooterComponent()" class="link-btn">{{btn2}}</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="app-ever-wrap-image">
                    <img [src]="sec3[0]?.img" alt="image">
                    <div class="wrap-shape">
                        <img [src]="sec3[0]?.img_bg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<!-- <div class="features-area pb-75">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-3 col-lg-4 col-sm-6 col-md-6">
                <div class="features-card style-two">
                    <div class="icon">
                        <i class="ri-smartphone-line"></i>
                        <div class="number">1</div>
                    </div>
                    <h3>User Friendly</h3>
                    <p>Most Provably best dashboard design for your business you can.</p>
                </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-sm-6 col-md-6">
                <div class="features-card style-two">
                    <div class="icon">
                        <i class="ri-award-line"></i>
                        <div class="number">2</div>
                    </div>
                    <h3>Award-Winning App</h3>
                    <p>Most Provably best dashboard design for your business you can.</p>
                </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-sm-6 col-md-6">
                <div class="features-card style-two">
                    <div class="icon">
                        <i class="ri-fingerprint-line"></i>
                        <div class="number">3</div>
                    </div>
                    <h3>Privacy Protected</h3>
                    <p>Most Provably best dashboard design for your business you can.</p>
                </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-sm-6 col-md-6">
                <div class="features-card style-two">
                    <div class="icon">
                        <i class="ri-vip-diamond-line"></i>
                        <div class="number">4</div>
                    </div>
                    <h3>Lifetime Update</h3>
                    <p>Most Provably best dashboard design for your business you can.</p>
                </div>
            </div>
        </div>
    </div>
</div> -->