<div class="integrate pb-75" [dir]="isRtl === 'Y' ? 'rtl' : 'ltr'">
    <div class="container">
        <div class="section-title">
            <h2>{{integ_hd}}</h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6" *ngFor="let feature of integ">
                <div class="single-features-item without-hover box-shadow">
                    <div class="icon">
                        <i [class]="feature.icon"></i>
                    </div>
                    <div class="content">
                        <h3>{{ feature.key }}</h3>
                        <p>{{ feature.desc }}</p>
                    </div>
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-sm-12 col-md-12">
                <div class="text-center">
                    <a routerLink="/demo-request" class="default-btn">{{btn1}}</a>
                </div>
            </div>
        </div>
        
    </div>
</div>