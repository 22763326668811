<div class="navbar-area navbar-style-two pt-100" [ngClass]="{ sticky: isSticky }" [dir]="isRtl === 'Y' ? 'rtl' : 'ltr'">
  <div class="container">
    <nav
      class="navbar navbar-expand-lg navbar-light bg-light"
      [class.active]="classApplied"
    >
      <a class="navbar-brand" routerLink="/"
        ><img src="assets/img/black-logo.png" alt="logo"
      /></a>
      <button class="navbar-toggler" type="button" (click)="toggleClass()">
        <span class="burger-menu">
          <span class="top-bar"></span>
          <span class="middle-bar"></span>
          <span class="bottom-bar"></span>
        </span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a href="javascript:void(0)" [routerLink]="['/home']" routerLinkActive="router-link-active"  class="nav-link"
              >{{
                headerMenu?.home
              }}</a
            >
          </li>
          <li class="nav-item">
            <a href="javascript:void(0)" [routerLink]="['/about-modern']" routerLinkActive="router-link-active"  class="nav-link"
              >{{
                headerMenu?.about_us
              }}</a
            >
          </li>
          <li class="nav-item"  *ngIf="headerMenu?.with_us_smenu && headerMenu.with_us_smenu.length > 0">
            <a href="javascript:void(0)" class="dropdown-toggle nav-link"
              >{{ headerMenu?.with_us }}</a
            >
            <ul class="dropdown-menu" [dir]="isRtl === 'Y' ? 'rtl' : 'ltr'">
              <li class="nav-item">
                <a
                  routerLink="/products"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >{{ headerMenu?.with_us_smenu[0].our_team }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/products"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >{{ headerMenu?.with_us_smenu[0].grow_with_us }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/products"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >{{ headerMenu?.with_us_smenu[0].partners }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/products"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >{{ headerMenu?.with_us_smenu[0].whysamasa }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/products"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >{{ headerMenu?.with_us_smenu[0].our_clients }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/products"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >{{ headerMenu?.with_us_smenu[0]['latest_blogs_&_news'] }}</a
                >
              </li>
            </ul>
          </li>
          <li class="nav-item"   *ngIf="headerMenu?.products_smenu && headerMenu.products_smenu.length > 0">
            <a href="javascript:void(0)" class="dropdown-toggle nav-link"
              >{{ headerMenu?.products }}</a
            >
            <ul class="dropdown-menu">
              <li class="nav-item">
                <a
                  routerLink="/details-page/komsys_pms"
                  class="nav-link"
                  routerLinkActive="active"
                  >{{ headerMenu?.products_smenu[0].komsys_pms }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/details-page/techsa_pos"
                  class="nav-link"
                  routerLinkActive="active"
                  >{{ headerMenu?.products_smenu[0].techsa_pos }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/details-page/accur_financials"
                  class="nav-link"
                  routerLinkActive="active"
                  >{{ headerMenu?.products_smenu[0].accur_financials }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/details-page/innvion_inventory"
                  class="nav-link"
                  routerLinkActive="active"
                  >{{ headerMenu?.products_smenu[0].innvion_inventory }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/details-page/hotel_connect_guest"
                  class="nav-link"
                  routerLinkActive="active"
                  >{{ headerMenu?.products_smenu[0].hotel_connect_guest }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/details-page/hotel_manager"
                  class="nav-link"
                  routerLinkActive="active"
                  >{{ headerMenu?.products_smenu[0].hotel_manager }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/details-page/interfaces"
                  class="nav-link"
                  routerLinkActive="active"
                  >{{ headerMenu?.products_smenu[0].interfaces }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/details-page/my_workdesk"
                  class="nav-link"
                  routerLinkActive="active"
                  >{{ headerMenu?.products_smenu[0].my_workdesk }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/details-page/my_restaurants"
                  class="nav-link"
                  routerLinkActive="active"
                  >{{ headerMenu?.products_smenu[0].my_restaurants }}</a
                >
              </li>
            </ul>
          </li>
          <li class="nav-item"  *ngIf="headerMenu?.solutions_smenu && headerMenu.solutions_smenu.length > 0">
            <a href="javascript:void(0)" class="dropdown-toggle nav-link"
              >{{ headerMenu?.solutions }}</a
            >
            <ul class="dropdown-menu">
              <li class="nav-item">
                <a
                  routerLink="/products"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >{{ headerMenu?.solutions_smenu[0].on_primise }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/products"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >{{ headerMenu?.solutions_smenu[0].cloud_solution }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/products"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >{{ headerMenu?.solutions_smenu[0].hospitality_solutions }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/products"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >{{ headerMenu?.solutions_smenu[0].my_workdesk }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/products"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >{{ headerMenu?.solutions_smenu[0].intergrations }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/products"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >{{ headerMenu?.solutions_smenu[0].hotel_manager }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/products"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >{{ headerMenu?.solutions_smenu[0].interfaces }}</a
                >
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a href="javascript:void(0)" class="dropdown-toggle nav-link"
              >{{ headerMenu?.contact_us }}</a
            >
            <ul class="dropdown-menu">
              <li class="nav-item">
                <a
                  routerLink="/blog-right-sidebar"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  ></a
                >
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a href="javascript:void(0)" class="dropdown-toggle nav-link navbar-globe"
              ><i class="ri-global-line"></i></a
            >
            <ul class="dropdown-menu">
              <ng-container *ngFor="let country of langCountry">
                <li class="nav-item dropdown-submenu">
                  <a href="javascript:void(0)" class="dropdown-toggle nav-link">{{ country.country_name }}</a>
                  <ul class="dropdown-menu sub-dropdown-menu">
                    <ng-container *ngFor="let lang of country.lng_data">
                      <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link" (click)="updateUserPreferences(country.country_code, country.country_name, lang.lang_code, lang.lang_desc,lang.is_rtl)">{{ lang.lang_desc }}</a>
                      </li>
                    </ng-container>
                  </ul>
                </li>
              </ng-container>
            </ul>
          </li>
        </ul>
        <div class="others-option">
          <a routerLink="/contact" class="default-btn" *ngIf="headerMenu?.support_24x7">{{ headerMenu?.support_24x7 }}</a>
        </div>
      </div>
    </nav>
  </div>
</div>
