<div class="container parent" [dir]="isRtl === 'Y' ? 'rtl' : 'ltr'">
    <div class="col-lg-12 col-md-12">
        <div class="products-details-tabs">
            <h1 class="services-title pb-3">{{ prod_l1 }}</h1>
            <ul class="nav-tabset flex-column flex-sm-row">
                <li class="nav-tab" id="nav-tab" [ngClass]="{'active': currentTab === tab.label}"
                    *ngFor="let tab of prod; let i = index;">
                    <span (click)="switchTab($event, tab.label)">{{ tab.heading }}</span>
                </li>
            </ul>
            <div class="tabs-container">
                <ng-container *ngFor="let tab of prod; let i = index;">
                    <div class="features-area" [ngClass]="{'active': currentTab === tab.label}"
                        *ngIf="currentTab === tab.label">
                        <div class="container1">
                            <div class="row justify-content-space-evenly">
                                <div
                                    *ngFor="let item of tab.items; let j = index;">
                                    <div class="features-box" [ngClass]="{'alternate': j % 2 !== 0}">
                                        <div class="feature-text">
                                          <h3>{{ item.label }}</h3>
                                          <p>{{ item.description }} <span><a [routerLink]="item.routerLink" class="read" routerLinkActive="router-link-active" ><b>{{read}}</b></a></span></p>
                                        </div>
                                        <img  [src]="item.img" alt="{{ item.label }}">
                                      </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>