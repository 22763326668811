import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { MultilingualService } from 'src/app/services/multilingual.service';

@Component({
  selector: 'app-solutions',
  templateUrl: './solutions.component.html',
  styleUrl: './solutions.component.scss'
})
export class SolutionsComponent implements OnInit {
  pageId!: string;
  section_id: string = "p1";
  solutions: any = {};
  isRtl: string = 'N';

  constructor(private multilingualService: MultilingualService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        this.pageId = params.get('pageId') || '';
        return this.multilingualService.getPageData(this.pageId, this.section_id);
      })
    ).subscribe(
      (response) => {
        this.solutions = response.data[0].page_data[0];
        // console.log('PageLoaded:', this.pageId);
      },
      (error) => {
        console.error('Error fetching solutions data', error);
      });

    this.multilingualService.getIsRtl().subscribe(isRtl => {
      this.isRtl = isRtl;
    });
  }
}
