import { Component, Input, OnInit, OnDestroy, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-solutions-banner',
  templateUrl: './solutions-banner.component.html',
  styleUrl: './solutions-banner.component.scss'
})
export class SolutionsBannerComponent implements OnInit, OnDestroy {
  @Input() heading: string = '';
  @Input() trial: string = '';
  @Input() btn1: string = '';
  @Input() banner: any[] = [];
  @Input() banner_photos: any[] = [];
  @Input() isRtl: string = 'N';

  currentBannerIndex: number = 0;
  bannerLoaded: boolean = false;
  fadeInOut: boolean = true;
  private destroy$: Subject<void> = new Subject<void>();
  private bannerInterval: any;

  constructor() {}

  ngOnInit() {
    this.startBannerInterval();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.banner && !changes.banner.firstChange) {
      this.startBannerInterval();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    clearInterval(this.bannerInterval);
  }

  startBannerInterval() {
    if (this.banner && this.banner.length > 0) {
      this.bannerLoaded = true;
      clearInterval(this.bannerInterval); // Clear existing interval
      this.currentBannerIndex = 0; // Reset to 0
      this.bannerInterval = setInterval(() => {
        this.fadeInOut = !this.fadeInOut;
        setTimeout(() => {
          this.fadeInOut = !this.fadeInOut;
          this.currentBannerIndex = (this.currentBannerIndex + 1) % this.banner.length;
        }, 800);
      }, 3500);
    }
  }

  currentTab = 'tab0';
  switchTab(event: MouseEvent, tab: string) {
    event.preventDefault();
    this.currentTab = tab;
  }
}
