import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, filter, map, switchMap, throwError } from "rxjs";
import { AppConfig } from "../app.config";
import { ActivatedRoute, Router } from "@angular/router";
import { catchError, take, tap } from "rxjs/operators";
import { Title } from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class MultilingualService {
  private baseUrl!: string;

  private userDetailsSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public userDetails$: Observable<any> = this.userDetailsSubject.asObservable();

  //loading GIF
  private isLoadingSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  public isLoading$: Observable<boolean> = this.isLoadingSubject.asObservable();

  constructor(
    private http: HttpClient,
    private appConfig: AppConfig,
    private router: Router,
    private route: ActivatedRoute,
    private title: Title
  ) {
    this.loadBaseUrl();
    this.setupPageTitleListener();
  }

  private setupPageTitleListener() {
    window.addEventListener('beforeunload', () => {
    });
  }

  private loadBaseUrl() {
    this.baseUrl = AppConfig.settings?.apiUrl;
    // console.log('Base URL loaded:', this.baseUrl);
  }

  // Save the user details
  setUserDetails(userDetails: any): void {
    // console.log(userDetails);
    this.userDetailsSubject.next(userDetails);
  }

  updateUserPreferences(
    countryCode: string,
    countryName: string,
    langCode: string,
    langDesc: string,
    isRtl: string
  ): void {
    this.userDetailsSubject.next({
      country: countryName,
      country_code: countryCode,
      lang_code: langCode,
      lang_desc: langDesc,
      is_rtl: isRtl,
    });

    this.route.url.subscribe((urlSegments) => {
      const currentUrl = urlSegments.join("/");

      // Subscribe to userDetails$ to wait for the updated userDetails
      this.userDetails$.subscribe((updatedUserDetails) => {
        if (updatedUserDetails) {
          this.router.navigateByUrl(currentUrl);
        }
      });
    });
  }

  getIsRtl(): Observable<string> {
    return this.userDetails$.pipe(
      filter((userDetails) => userDetails !== null),
      map((userDetails) => userDetails.is_rtl || "N") // Default to 'N' if undefined
    );
  }

  // Method to set hard coded user details
  setHardCodedUserDetails(): void {
    const hardCodedDetails = {
      session_id: "c6bb8b59-070f-44b5-aefc-06ec6948b28f",
      country: "India",
      country_code: "IN",
      lang_code: "en",
      lang_desc: "English",
      is_rtl: "Y",
    };
    this.setUserDetails(hardCodedDetails);
  }

  // Limited calls constraint
  fetchUserDetailsFromAPI(): void {
    this.isLoadingSubject.next(true);
    this.http
      .get<any>(`${this.baseUrl}/0608T/D10P21/CAF2481BFE584C38FB29114F5DEF5`)
      .subscribe(
        (response) => {
          if (response.status === 200) {
            this.setUserDetails(response.data);
          }
          this.isLoadingSubject.next(false);
        },
        (error) => {
          console.error("Error fetching user details:", error);
          this.isLoadingSubject.next(false);
        }
      );
  }

  getHeaderData(): Observable<any> {
    this.isLoadingSubject.next(true);
    // console.log('Fetching user details from:', this.baseUrl);
    return this.userDetails$.pipe(
      switchMap((userDetails) => {
        return this.http
          .get(`${this.baseUrl}/0608T/D10P21/getMenuMaster`, {
            params: {
              country_code: userDetails?.country_code || "GBL", // Default to 'GBL'
              lng_code: userDetails?.lang_code || "en", // Default to 'en'
            },
          })
          .pipe(
            tap(() => {
              this.isLoadingSubject.next(false);
            })
          );
      })
    );
  }

  getLanguageData(): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${this.baseUrl}/0608T/D10P21/YkahkdxV`).pipe(
      tap(() => {
        this.isLoadingSubject.next(false);
      })
    );
  }

  getPageData(pageId: string, sectionId: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.userDetails$.pipe(
      // Only emit a value if userDetails is not null
      filter((userDetails) => userDetails !== null),
      switchMap((userDetails) => {
        const params = {
          session_id: userDetails.session_id,
          country_code: userDetails.country_code || "GBL",
          page_id: pageId,
          lng_code: userDetails.lang_code || "en", // Default to 'en'
          section_id: sectionId,
        };
        return this.http
          .get(`${this.baseUrl}/0608T/D10P21/7ADC224AC32E8`, { params })
          .pipe(
            tap((data: any) => {
              const pageTitle = data?.data?.[0]?.page_data?.[0]?.page_title || "SAMASA";
              this.title.setTitle(pageTitle);
              localStorage.setItem('pageTitle', pageTitle);
              this.isLoadingSubject.next(false);
            })
          );
      })
    );
  }

  getFooterData(): Observable<any> {
    return this.userDetails$.pipe(
      switchMap((userDetails) => {
        return this.http.get(`${this.baseUrl}/0608T/D10P21/getFooterMaster`, {
          params: {
            country_code: userDetails?.country_code || "GBL", // Default to 'GBL'
            lng_code: userDetails?.lang_code || "en", // Default to 'en'
          },
        });
      })
    );
  }

  submitDemoRequest(data: any): Promise<any> {
    // console.log(this.userDetails$);
    // console.log(data);

    this.isLoadingSubject.next(true);
    return this.userDetails$
      .pipe(
        take(1),
        switchMap((userDetails) => {
          data.session_id = userDetails?.session_id || "";
          return this.http.post(`${this.baseUrl}/0608T/D10P21/demorequest`, data)
            .pipe(
              tap(() => this.isLoadingSubject.next(false)), // Set isLoadingSubject to false on success
              catchError(this.handleError) // Handle errors
            );
        })
      )
      .toPromise();
  }

  private handleError(error: any) {
    this.isLoadingSubject.next(false);
    console.error('An error occurred:', error);
    return throwError(error);
  }

  saveUserMaster(userMaster: any): Promise<any> {
    return this.http
      .post(`${this.baseUrl}/0608T/D10P21/demorequest`, userMaster)
      .toPromise()
      .then((response) => response as any)
      .catch(this.handleError);
  }
}
