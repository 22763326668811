import { Component, Input, OnInit } from '@angular/core';
import * as L from 'leaflet';
import { PopupData, MarkerInfo, RegionMarkerData } from '../popup-data.model';

@Component({
  selector: 'app-contact-us-map',
  templateUrl: './contact-us-map.component.html',
  styleUrls: ['./contact-us-map.component.scss']
})
export class ContactUsMapComponent implements OnInit {
  @Input() popup: PopupData | undefined;
  @Input() add_hd: string="";
  @Input() phone_hd: string="";
  constructor() { }

  ngOnInit(): void {
    this.initializeMap();
  }

  private initializeMap(): void {
    const map = L.map('map').setView([23.4241, 53.8478], 2 );
    map.setMinZoom(3);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    const customIcon = L.icon({
      iconUrl: 'assets/img/map-pin.png',
      iconSize: [32, 32],
      iconAnchor: [16, 32],
      popupAnchor: [0, -32]
    });

    if (this.popup){
      for (const region of Object.keys(this.popup) as (keyof typeof this.popup)[]) {
        const { region: regionName, markers } = this.popup[region] as RegionMarkerData;
        // console.log("Region:", regionName);
  
        for (const markerInfo of markers as MarkerInfo[]) {
          const marker = L.marker(markerInfo.coords as L.LatLngExpression, { icon: customIcon }).addTo(map);
          
          const content = `
            <div class="mapPopupForContactUs" [dir]="isRtl === 'Y' ? 'rtl' : 'ltr'">
              <div class="country">
                <img class="flag" src="${markerInfo.flag}" alt="Flag">
                <h6>&nbsp;${markerInfo.title}</h6>
              </div>
              <hr>
              <p><strong>${this.add_hd}</strong> ${markerInfo.address}</p>
              <p class="phone"><strong>${this.phone_hd}</strong> ${markerInfo.phone}</p>
            </div>
          `;
  
          marker.bindPopup(content, {
            className: 'custom-popup'
          });
  
          marker.on('mouseover', () => {
            marker.openPopup();
          });
  
          marker.on('mouseout', () => {
            marker.closePopup();
          });
        }
      }
    }
  }
}