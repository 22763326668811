import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-details-page-about',
  templateUrl: './details-page-about.component.html',
  styleUrl: './details-page-about.component.scss'
})
export class DetailsPageAboutComponent {
  @Input() about_hd: string = '';
  @Input() btn1: string = '';
  @Input() btn2: string = '';
  @Input() about: any[] = [];
  @Input() about_img: string = '';
  @Input() isRtl: string = 'N';

}
