<div class="app-progress-area pt-100 pb-100" [dir]="isRtl === 'Y' ? 'rtl' : 'ltr'">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="app-progress-content">
                    <h1 class="pb-3">{{cli_l1}}</h1>
                    <ul class="features-list" *ngIf="cli && cli.length">
                        <li *ngFor="let item of cli" (mouseover)="showImage(item)">
                            <div class="icon">
                                <i [class]="item.icon"></i>
                            </div>
                            <h3>{{item.label}}</h3>
                            <p>{{item.desc}}</p>
                        </li>
                    </ul>
                    <a routerLink="/demo-request" class="default-btn">{{btn1}}</a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="features-image text-center">
                    <img src="{{ currentImage ? currentImage : cli_img }}" alt="app-img" data-aos="fade-up">
                </div>
            </div>
        </div>
    </div>
</div>