import { Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-homenine-banner',
  templateUrl: './homenine-banner.component.html',
  styleUrls: ['./homenine-banner.component.scss'],
})
export class HomenineBannerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() sec1_p1: string = '';
  @Input() banner: any[] = [];
  @Input() banner_photos: any[] = [];
  @Input() sec1_p2: string = '';
  @Input() btn1: string = '';
  @Input() isRtl: string = 'N'; // Default to 'N'
  
  currentBannerIndex: number = 0;
  currentImageIndex: number = 0;
  bannerLoaded: boolean = false;
  fadeInOutText: boolean = true;
  fadeInOutImage: boolean = true;
  private destroy$: Subject<void> = new Subject<void>();
  private bannerInterval: any;
  private imageRotationInterval: any;

  constructor() { }

  ngOnInit() {
    this.startBannerInterval();
    this.startImageRotation();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.banner && !changes.banner.firstChange) {
      this.startBannerInterval();
    }
    if (changes.banner_photos && !changes.banner_photos.firstChange) {
      this.startImageRotation();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    clearInterval(this.bannerInterval);
    clearInterval(this.imageRotationInterval);
  }

  startBannerInterval() {
    if (this.banner && this.banner.length > 0) {
      this.bannerLoaded = true;
      clearInterval(this.bannerInterval); // Clear existing interval
      this.currentBannerIndex = 0; // Reset to 0
      this.bannerInterval = setInterval(() => {
        this.fadeInOutText = !this.fadeInOutText;
        setTimeout(() => {
          this.fadeInOutText = !this.fadeInOutText;
          this.currentBannerIndex = (this.currentBannerIndex + 1) % this.banner.length;
        }, 800);
      }, 3500);
    }
  }

  startImageRotation() {
    if (this.banner_photos && this.banner_photos.length > 0) {
      clearInterval(this.imageRotationInterval); // Clear existing interval
      this.currentImageIndex = 0; // Reset to 0
      this.imageRotationInterval = setInterval(() => {
        this.fadeInOutImage = !this.fadeInOutImage;
        setTimeout(() => {
          this.fadeInOutImage = !this.fadeInOutImage;
          this.currentImageIndex = (this.currentImageIndex + 1) % this.banner_photos.length;
        }, 750);
      }, 3500);
    }
  }

  getCurrentImageUrl(): string {
    if (this.banner_photos && this.banner_photos.length > 0) {
      return this.banner_photos[this.currentImageIndex].label;
    }
    return '';
  }
}
