<div
  class="page-title-area page-title-style-two"
  [dir]="isRtl === 'Y' ? 'rtl' : 'ltr'"
  id="contact-us"
>
  <div class="container">
    <div class="page-title-content">
      <h2>{{ contact?.contact }}</h2>
      <!-- <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About Us</li>
            </ul> -->
    </div>
  </div>
  <!-- <div class="divider"></div>
  <div class="lines">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
  </div>
  <div class="banner-shape1">
    <img src="assets/img/shape/shape9.png" alt="image" />
  </div> -->
</div>

<ng-container *ngIf="contact && contact.popup && contact.popup.length > 0">
  <app-contact-us-map
    *ngIf="show"
    [popup]="contact?.popup[0]"
    [add_hd]="contact?.add_hd"
    [phone_hd]="contact?.phone_hd"
  ></app-contact-us-map>
</ng-container>

<ng-container *ngIf="contact && contact.popup && contact.popup.length > 0">
  <app-contact-us-offices
    [offices]="contact?.offices"
    [popup]="contact?.popup[0]"
    [add_hd]="contact?.add_hd"
    [phone_hd]="contact?.phone_hd"
    [isRtl]="isRtl"
  ></app-contact-us-offices>
</ng-container>

<app-contact-us-partners
  [partners_hd]="contact?.partners_hd"
  [partners]="contact?.partners"
  [add_hd]="contact?.add_hd"
  [phone_hd]="contact?.phone_hd"
  [isRtl]="isRtl"
></app-contact-us-partners>

<app-contact-us-support
  id="support"
  [cust]="contact?.cust"
  [ticket_hd]="contact?.ticket_hd"
  [ticket_link]="contact?.ticket_link"
  [help]="contact?.help"
  [help_link]="contact?.help_link"
  [faq]="contact?.faq"
  [faq_link]="contact?.faq_link"
  [isRtl]="isRtl"
></app-contact-us-support>
