<div class="features-area ptb-100">
  <div class="container">
    <div class="row justify-content-center" [dir]="isRtl === 'Y' ? 'rtl' : 'ltr'">
      <h3 class="advantages-title pb-3">{{sec2_l1}}</h3>
      <div class="col-xl-4 col-lg-6 col-md-6" *ngIf="sec2 && sec2.length > 0">
        <div class="single-features-box">
          <div class="icon">
            <i class="ri-device-line"></i>
          </div>
          <h3>{{sec2[0]?.sec2_key1}}</h3>
          <p *ngIf="sec2[0]?.sec2_pnl1">{{sec2[0]?.sec2_pnl1}}</p>
        </div>
      </div>
      <div class="col-xl-4 col-lg-6 col-md-6" *ngIf="sec2 && sec2.length > 0">
        <div class="single-features-box">
          <div class="icon bg2">
            <i class="ri-community-line"></i>
          </div>
          <h3>{{sec2[0]?.sec2_key2}}</h3>
          <p *ngIf="sec2[0]?.sec2_pnl2">{{sec2[0]?.sec2_pnl2}}</p>
        </div>
      </div>
      <div class="col-xl-4 col-lg-6 col-md-6" *ngIf="sec2 && sec2.length > 0">
        <div class="single-features-box">
          <div class="icon bg3">
            <i class="ri-translate-2"></i>
          </div>
          <h3>{{sec2[0]?.sec2_key3}}</h3>
          <p *ngIf="sec2[0]?.sec2_pnl3">{{sec2[0]?.sec2_pnl3}}</p>
        </div>
      </div>
      <div class="col-xl-4 col-lg-6 col-md-6" *ngIf="sec2 && sec2.length > 0">
        <div class="single-features-box">
          <div class="icon bg4">
            <i class="ri-microscope-line"></i>
          </div>
          <h3>{{sec2[0]?.sec2_key4}}</h3>
          <p *ngIf="sec2[0]?.sec2_pnl4">{{sec2[0]?.sec2_pnl4}}</p>
        </div>
      </div>
      <div class="col-xl-4 col-lg-6 col-md-6" *ngIf="sec2 && sec2.length > 0">
        <div class="single-features-box">
          <div class="icon bg5">
            <i class="ri-emotion-happy-line"></i>
          </div>
          <h3>{{sec2[0]?.sec2_key5}}</h3>
          <p *ngIf="sec2[0]?.sec2_pnl5">{{sec2[0]?.sec2_pnl5}}</p>
        </div>
      </div>
      <div class="col-xl-4 col-lg-6 col-md-6" *ngIf="sec2 && sec2.length > 0">
        <div class="single-features-box">
          <div class="icon bg6">
            <i class="ri-function-add-line"></i>
          </div>
          <h3>{{sec2[0]?.sec2_key6}}</h3>
          <p *ngIf="sec2[0]?.sec2_pnl6">{{sec2[0]?.sec2_pnl6}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
