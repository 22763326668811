<div class="about-area pb-75" [dir]="isRtl === 'Y' ? 'rtl' : 'ltr'">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <!-- <span class="sub-title">ABOUT US</span> -->
                    <h2>{{about_hd}}</h2>
                    <div *ngFor="let item of about" class="features-text">
                        <p>{{ item.label }}</p>
                    </div>
                    <div class="btn-box">
                        <a routerLink="/demo-request" class="default-btn">{{btn1}}</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-img">
                    <img [src]="about_img" data-aos="fade-up" alt="about">
                </div>
            </div>
        </div>
    </div>
</div>