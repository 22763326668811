import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-style-one',
  templateUrl: './progress-style-one.component.html',
  styleUrls: ['./progress-style-one.component.scss']
})
export class ProgressStyleOneComponent implements OnInit {
  @Input() cli_l1: string = '';
  @Input() cli: any[] = [];
  @Input() btn1: string = '';
  @Input() cli_img: string = '';
  @Input() isRtl: string = 'N';

  constructor() { }

  ngOnInit(): void {
  }

  currentImage: string = "";
  showImage(item: any) {
    this.currentImage = item.img;
  }

}
