<div class="team-area pt-100 pb-75" [dir]="isRtl === 'Y' ? 'rtl' : 'ltr'">
    <div class="container">
        <div class="section-title">
            <!-- <span class="sub-title">OUR EXPERTS</span> -->
            <h2>{{sign_l1}}</h2>
        </div>
        <div class="row justify-content-center" *ngIf="sign && sign.length">
            <div class="col-lg-3 col-md-6 col-sm-6" *ngFor="let product of sign">
                <div class="single-team-member">
                    <div class="image">
                        <!-- <img class="product-logo" [src]="'assets/img/num' + product.num + '-image.png'" alt="Product Image" /> -->
                        <img [src]="product.img" alt="image">
                    </div>
                    <div class="content">
                        <h3>{{ product.key }}</h3>
                        <span>{{ product.desc }}</span>
                    </div>
                    <div class="btn-box mt-5">
                        <a [routerLink]="product.signinLink"  class="red default-btn mb-2">{{signin}}</a>
                        <a [routerLink]="product.signinLink"  class="blue default-btn mb-2">{{signup}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>