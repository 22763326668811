<!-- <div class="container">

    <h2>Details Page for ID: {{ pageId }}</h2>
</div> -->

<app-details-page-banner
    [heading]="product?.heading"
    [trial]="product?.trial"
    [btn1]="product?.btn1"
    [banner]="product?.banner"
    [banner_photos]="product?.banner_photos"
    [isRtl]="isRtl"
></app-details-page-banner>

<app-details-page-about
    [about_hd]="product?.about_hd"
    [btn1]="product?.btn1"
    [btn2]="product?.btn2"
    [about]="product?.about"
    [about_img]="product?.about_img"
    [isRtl]="isRtl"
></app-details-page-about>

<app-details-page-features
    [feat_hd]="product?.feat_hd"
    [feat]="product?.feat"
    [isRtl]="isRtl"
></app-details-page-features>

<app-details-page-benefits
    [ben_hd]="product?.ben_hd"
    [ben]="product?.ben"
    [isRtl]="isRtl"
></app-details-page-benefits>

<app-details-page-asc-services
    [serv_hd]="product?.serv_hd"
    [defaultImageS]="product?.defaultS_img"
    [btn1]="product?.btn1"
    [btn2]="product?.btn2"
    [serv]="product?.serv"
    [isRtl]="isRtl"
></app-details-page-asc-services>

<app-details-page-integrate
    [integ_hd]="product?.integ_hd"
    [btn1]="product?.btn1"
    [integ]="product?.integ"
    [isRtl]="isRtl"
></app-details-page-integrate>

<app-details-page-module
    [mod_hd]="product?.mod_hd"
    [defaultImageM]="product?.defaultM_img"
    [btn1]="product?.btn1"
    [btn2]="product?.btn2"
    [mod]="product?.mod"
    [isRtl]="isRtl"
></app-details-page-module>

<app-details-page-about
    [about_hd]="product?.summary_hd"
    [btn1]="product?.btn1"
    [btn2]="product?.btn2"
    [about]="product?.summary"
    [about_img]="product?.summary_img"
    [isRtl]="isRtl"
></app-details-page-about>