import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-contact-us-support',
  templateUrl: './contact-us-support.component.html',
  styleUrls: ['./contact-us-support.component.scss']
})
export class ContactUsSupportComponent {
  @Input() cust: any;
  @Input() ticket_hd: string="";
  @Input() ticket_link: string="";
  @Input() help: string="";
  @Input() help_link: string="";
  @Input() faq: string="";
  @Input() faq_link: string="";
  @Input() isRtl: string="";
}
