import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-homenine-products',
  templateUrl: './homenine-products.component.html',
  styleUrl: './homenine-products.component.scss',
})
export class HomenineProductsComponent implements OnInit {
  @Input() sec4_l1: string = '';
  @Input() sec4: any[] = []; 
  @Input() isRtl: string = 'N'; // Default to 'N'

  constructor() {}

  ngOnInit(): void {
  }

  // Tabs --Code by Aakash
  currentTab = 'tab1';
  switchTab(event: MouseEvent, tab: string) {
    event.preventDefault();
    this.currentTab = tab;
  }
}
