import { Component, OnInit, HostListener } from '@angular/core';
import { MultilingualService } from 'src/app/services/multilingual.service';

@Component({
  selector: 'app-navbar-style-one',
  templateUrl: './navbar-style-one.component.html',
  styleUrls: ['./navbar-style-one.component.scss'],
})
export class NavbarStyleOneComponent implements OnInit {
  headerMenu: any={};

  constructor(private multilingualService: MultilingualService) {}

  ngOnInit(): void {
    // To change header start -- By AD✨ 
    this.multilingualService.getHeaderData().subscribe(
      (data) => {
        this.headerMenu = data?.data[0]?.menu_data[0];
        // console.log(this.headerMenu);
      },
      (error) => {
        console.error('Error fetching header menu:', error);
      }
    );
    // To change header end -- By AD🌌
  }

  classApplied = false;
  toggleClass() {
    this.classApplied = !this.classApplied;
  }

  // Navbar Sticky
  isSticky: boolean = false;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (scrollPosition >= 50) {
      this.isSticky = true;
    } else {
      this.isSticky = false;
    }
  }

  // Fetch data from API for nav-bar --By AD✨
}
