<app-solutions-banner
    [heading]="solutions?.heading"
    [trial]="solutions?.trial"
    [btn1]="solutions?.btn1"
    [banner]="solutions?.banner"
    [banner_photos]="solutions?.banner_photos"
    [isRtl]="isRtl"
></app-solutions-banner>

<app-solutions-about
    [about_hd]="solutions?.about_hd"
    [btn1]="solutions?.btn1"
    [btn2]="solutions?.btn2"
    [about]="solutions?.about"
    [about_img]="solutions?.about_img"
    [isRtl]="isRtl"
></app-solutions-about>

<app-solutions-features
    [feat_hd]="solutions?.feat_hd"
    [feat]="solutions?.feat"
    [isRtl]="isRtl"
></app-solutions-features>