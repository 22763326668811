<div class="page-title-area page-title-style-two" [dir]="isRtl === 'Y' ? 'rtl' : 'ltr'">
    <div class="container">
        <div class="page-title-content">
            <h2>{{about?.hd}}</h2>
            <!-- <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About Us</li>
            </ul> -->
        </div>
    </div>
    <div class="divider"></div>
    <!-- <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div> -->
    <div class="banner-shape1"><img src="assets/img/shape/shape9.png" alt="image"></div>
</div>

<div class="about-area" [dir]="isRtl === 'Y' ? 'rtl' : 'ltr'">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <!-- <span class="sub-title">{{about?.abt_l1}}</span> -->
                    <h2>{{about?.abt_l1}}</h2>
                    <p>{{about?.abt_p1}}</p>
                    <div class="features-text">
                        <h6>{{about?.abt_l2}}</h6>
                        <p>{{about?.abt_p2}}</p>
                    </div>
                    <div class="features-text">
                        <h6>{{about?.abt_l3}}</h6>
                        <p>{{about?.abt_p3}}</p>
                    </div>
                    <div class="btn-box">
                        <a routerLink="/demo-request" class="default-btn">{{about?.btn1}}</a>
                        <a (click)="scrollToFooterComponent()" class="link-btn">{{about?.btn2}}</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-img">
                    <img [src]="about?.abt_img" data-aos="fade-up" alt="about">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="about-area pt-100" [dir]="isRtl === 'Y' ? 'rtl' : 'ltr'">
    <div class="container">
        <div class="row align-items-center text-image-reverser">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <h2>{{about?.vsn_l1}}</h2>
                    <p>{{about?.vsn_p1}}</p>
                    <p>{{about?.vsn_p2}}</p>
                    <p>{{about?.vsn_p3}}</p>
                    <p>{{about?.vsn_p4}}</p>
                    <p>{{about?.vsn_p5}} <span><b>{{about?.vsn_p5_bold}}</b></span></p>
                    <div class="features-text">
                        <h6>{{about?.vsn_l2}}</h6>
                        <div *ngIf="about?.vsn && about?.vsn.length > 0">
                            <div *ngFor="let item of about?.vsn[0] | keyvalue" class="vsn-item">
                                <p>{{ item?.value }}</p>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="features-text">
                        <h6>{{about?.msn_l1}}</h6>
                        <p>Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy of your business purpose text.</p>
                    </div> -->
                    <div class="btn-box">
                        <a routerLink="/demo-request" class="default-btn">{{about?.btn1}}</a>
                        <a (click)="scrollToFooterComponent()" class="link-btn">{{about?.btn2}}</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-img">
                    <img [src]="about?.vsn_img" data-aos="fade-up" alt="about">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="about-area pt-100" [dir]="isRtl === 'Y' ? 'rtl' : 'ltr'">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <h2>{{about?.msn_l1}}</h2>
                    <p>{{about?.msn_desc}}</p>
                    <div *ngIf="about?.msn_p1 && about?.msn_p1.length > 0">
                        <div *ngFor="let item of about?.msn_p1[0] | keyvalue" class="msn_p1-item">
                            <p>{{ item?.value }}</p>
                        </div>
                    </div>
                    <br>
                    <p>{{about?.msn_l2}}</p>
                    <div class="features-text">
                        <h6>{{about?.msn_l3}}</h6>
                        <div *ngIf="about?.msn_p2 && about?.msn_p2.length > 0">
                            <div *ngFor="let item of about?.msn_p2[0] | keyvalue" class="msn_p2-item">
                                <p>{{ item?.value }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="btn-box">
                        <a routerLink="/demo-request" class="default-btn">{{about?.btn1}}</a>
                        <a (click)="scrollToFooterComponent()" class="link-btn">{{about?.btn2}}</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-img">
                    <img [src]="about?.msn_img" data-aos="fade-up" alt="about">
                </div>
            </div>
        </div>
    </div>
</div>

<app-why-us
    [why_hd]="about?.why_hd"
    [why_q]="about?.why_q"
    [why_l1]="about?.why_l1"
    [why_p1]="about?.why_p1"
    [why]="about?.why"
    [why_p2]="about?.why_p2"
    [isRtl]="isRtl"
></app-why-us>

<!-- <app-team-style-two></app-team-style-two> -->

<!-- <div class="app-download-area">
    <app-download-app-style-one></app-download-app-style-one>
</div> -->

<!-- <div class="funfacts-area ptb-100">
    <app-funfacts-style-two></app-funfacts-style-two>
</div> -->

<!-- <app-feedback-style-four></app-feedback-style-four> -->

<!-- <div class="pt-100">
    <app-homethree-video></app-homethree-video>
</div> -->

<!-- <div class="partner-area">
    <div class="container">
        <div class="partner-title">
            Trusted by world famous companies:
        </div>
        <app-partner></app-partner>
    </div>
</div> -->

<!-- <app-software-integrations></app-software-integrations> -->