import { Component, Input } from '@angular/core';
import { PopupData } from '../popup-data.model';

@Component({
  selector: 'app-contact-us-offices',
  templateUrl: './contact-us-offices.component.html',
  styleUrls: ['./contact-us-offices.component.scss']
})
export class ContactUsOfficesComponent {
  @Input() offices: string="";
  @Input() popup: PopupData | undefined;
  @Input() add_hd: string="";
  @Input() phone_hd: string="";
  @Input() isRtl: string="";

  getRegions(): string[] {
    if (!this.popup) return [];
    return Object.keys(this.popup);
  }
}
