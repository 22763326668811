import { ViewportScroller } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MultilingualService } from 'src/app/services/multilingual.service';

@Component({
  selector: 'app-navbar-style-six',
  templateUrl: './navbar-style-six.component.html',
  styleUrl: './navbar-style-six.component.scss'
})
export class NavbarStyleSixComponent implements OnInit {
  isRtl: string = 'N'; // Default to 'N'
  headerMenu: any = {};
  flag: string='';
  langCode: string='';
  langCountry: any= {};
  pageId: string='';

  add_flag: string="N";
  add: any = {};

  constructor(private multilingualService: MultilingualService, private router: Router, private viewportScroller: ViewportScroller) {}

  ngOnInit(): void {
    // To change header and make RTL friendly start -- By AD✨
    this.multilingualService.getIsRtl().subscribe((isRtl) => {
      this.isRtl = isRtl;
    });
    this.multilingualService.getHeaderData().subscribe(
      (data) => {
        this.headerMenu = data?.data[0]?.menu_data[0];
        this.flag=data?.data[0]?.country_flag;
        this.langCode = (data?.data[0]?.lng_code || '').charAt(0).toUpperCase() + (data?.data[0]?.lng_code || '').slice(1);

        this.add_flag = data?.data[0]?.add_data;
        this.add = data?.data[0]?.add_dtl[0];
        // console.log(this.add?.add_data[0]?.width);
      },
      (error) => {
        console.error('Error fetching header menu:', error);
      }
    );
    // To change header and make RTL friendly end -- By AD🌌

    // Fetching data for language/region start --By AD✨
    this.multilingualService.getLanguageData().subscribe(
      (response) => {
        this.langCountry = response?.data;
        // console.log(this.langCountry);
      },
      (error) => {
        console.error('Error fetching language and country options:', error);
      }
    )
    // Fetching data for language/region end --By AD✨
  }

  // Navbar Sticky
  isSticky: boolean = false;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (scrollPosition >= 50) {
      this.isSticky = true;
    } else {
      this.isSticky = false;
    }
  }

  classApplied = false;
  toggleClass(show: boolean) {
    this.classApplied = show;
  }

  openModal(){
    const modal=document.getElementById("myModal");
    if(modal){
      modal.style.display="block";
    }

    console.log(this.headerMenu);
  }

  closeModal(){
    const modal=document.getElementById("myModal");
    if(modal){
      modal.style.display="none";
    }
  }

  updateUserPreferences(
    countryCode: string, 
    countryName:string, 
    langCode: string,
    langDesc: string,
    isRtl: string){
      // console.log(countryCode, countryName, langCode, langDesc, isRtl);
      this.multilingualService.updateUserPreferences(countryCode, countryName, langCode, langDesc, isRtl);
      this.closeModal();
    }

    // ngAfterViewInit(): void {
    //   this.viewportScroller.setOffset([0, 64]);
    // }

    scrollToSection(page: string, sectionId: string) {
      this.router.navigate([page]).then(() => {
        setTimeout(() => {
          this.checkAndScroll(sectionId);
        }, 100);
      });
    }
    
    checkAndScroll(sectionId: string, attempts: number = 0) {
      const maxAttempts = 10;
      const element = document.getElementById(sectionId);
    
      if (element) {
        this.viewportScroller.scrollToAnchor(sectionId);
      } else {
        if (attempts < maxAttempts) {
          setTimeout(() => {
            this.checkAndScroll(sectionId, attempts + 1);
          }, 100);
        }
      }
    }

    openSubMenu(submenu: HTMLElement) {
      this.setClipPath(submenu, 'polygon(0 0, 100% 0, 100% 102%, 0 102%)');
      submenu.dataset.closed = 'false'; // Set the 'closed' property to false
  }
  
  closeSubMenu(submenu: HTMLElement) {
      this.setClipPath(submenu, 'polygon(0 0, 100% 0, 100% 0, 0 0)');
      submenu.dataset.closed = 'true'; // Set the 'closed' property to true
  }
  
  setClipPath(submenu: HTMLElement, clipPath: string) {
      submenu.style.clipPath = clipPath;
  }

  // @HostListener('window:scroll', [])
  // onWindowScroll() {
  //   this.calculateBottomPosition();
  // }
  calculateBottomPosition(): string {
    const height = this.add?.add_data[0]?.height || 0;
    const viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    const bottomPosition = viewportHeight - height;
    return bottomPosition + 'px';
  }

  closeAd(): void{
    this.add_flag="N";
  }
  
}
