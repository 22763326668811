import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-homenine-advantages',
  templateUrl: './homenine-advantages.component.html',
  styleUrl: './homenine-advantages.component.scss'
})
export class HomenineAdvantagesComponent implements OnInit {
  @Input() sec2_l1: string = '';
  @Input() sec2: any[] = [];
  @Input() isRtl: string = 'N'; // Default to 'N'

  constructor() { }

  ngOnInit(): void {
  }
}
