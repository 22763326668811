import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-why-us',
  templateUrl: './why-us.component.html',
  styleUrl: './why-us.component.scss'
})
export class WhyUsComponent {
  @Input() why_hd: string='';
  @Input() why_q: string='';
  @Input() why_l1: string='';
  @Input() why_p1: string='';
  @Input() why: any[] = [];
  @Input() why_p2: string='';
  @Input() isRtl: string="N";
  
  constructor(){
  }

}
