import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-solutions-features',
  templateUrl: './solutions-features.component.html',
  styleUrl: './solutions-features.component.scss'
})
export class SolutionsFeaturesComponent {
  @Input() feat_hd: string = '';
  @Input() feat: any[] = [];
  @Input() isRtl: string = 'N';
}
