import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class AppConfig {
  static settings: any;

  constructor(private http: HttpClient) {}

  load(): Promise<any> {
    // console.log("app-config loaded");
    const jsonFile = `assets/config/config.${environment.envName}.json`;
    return new Promise<void>((resolve, reject) => {
      this.http.get(jsonFile).toPromise().then((response: any) => {
        AppConfig.settings = response;
        // console.log('AppConfig loaded:', AppConfig.settings);
        resolve();
      }).catch((error: any) => {
        reject(`Could not load file '${jsonFile}': ${JSON.stringify(error)}`);
      });
    });
  }
}
