import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-details-page-features',
  templateUrl: './details-page-features.component.html',
  styleUrl: './details-page-features.component.scss'
})
export class DetailsPageFeaturesComponent {
  @Input() feat_hd: string = '';
  @Input() feat: any[] = [];
  @Input() isRtl: string = 'N';

}
