<app-product-signin-two
    [sign_l1]="footer?.sign_l1"
    [sign]="footer?.sign"
    [signin]="footer?.signin"
    [signup]="footer?.signup"
    [isRtl]="isRtl"
></app-product-signin-two>


<!-- <app-product-signin
    [sign_l1]="footer?.sign_l1"
    [sign]="footer?.sign"
    [signin]="footer?.signin"
    [signup]="footer?.signup"
    [isRtl]="isRtl"
></app-product-signin> -->

<app-products
  [prod_l1]="footer?.prod_l1"
  [prod]="footer?.prod"
  [btn1]="footer?.btn1"
  [read]="footer?.read"
  [isRtl]="isRtl"
  id="footerProductDetails"
></app-products>

<app-progress-style-one
  [cli_l1]="footer?.cli_l1"
  [cli]="footer?.cli"
  [btn1]="footer?.btn1"
  [cli_img]="footer?.cli_img"
  [isRtl]="isRtl"
></app-progress-style-one>

<div class="footer-area" [dir]="isRtl === 'Y' ? 'rtl' : 'ltr'">
    <div class="container">
        <div class="footer-content">
            <a routerLink="/" class="logo">
                <img src="assets/img/logo2.png" alt="logo">
            </a>
            <ul class="social-links">
                <li><a href="#" target="_blank"><i class="ri-facebook-fill"></i></a></li>
                <li><a href="#" target="_blank"><i class="ri-twitter-fill"></i></a></li>
                <li><a href="#" target="_blank"><i class="ri-linkedin-fill"></i></a></li>
                <li><a href="#" target="_blank"><i class="ri-messenger-fill"></i></a></li>
                <li><a href="#" target="_blank"><i class="ri-github-fill"></i></a></li>
            </ul>
            <ul class="navbar-nav">
                <li class="nav-item"><a class="nav-link">{{footer?.priv}}</a></li>
                <li class="nav-item"><a class="nav-link">{{footer?.tnc}}</a></li>
                <li class="nav-item"><a class="nav-link">{{footer?.sla}}</a></li>
                <li class="nav-item"><a class="nav-link">{{footer?.smp1}}</a></li>
                <li class="nav-item"><a class="nav-link">{{footer?.smp2}}</a></li>
            </ul>
            <p class="copyright">{{footer?.rights}}<a [routerLink]="['/home']" routerLinkActive="router-link-active"  target="_blank">{{footer?.name}}</a></p>
        </div>
    </div>
</div>