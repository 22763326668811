import { Component, OnInit } from "@angular/core";
import { MultilingualService } from "src/app/services/multilingual.service";

@Component({
  selector: "app-footer-style-one",
  templateUrl: "./footer-style-one.component.html",
  styleUrls: ["./footer-style-one.component.scss"],
})
export class FooterStyleOneComponent implements OnInit {
  page_id: string = "footer";
  section_id: string = "p1";
  footer: any = {};
  isRtl: string = "N";

  constructor(private multilingualService: MultilingualService) {}

  ngOnInit(): void {
    this.multilingualService.getIsRtl().subscribe((isRtl) => {
      this.isRtl = isRtl;
    });
    this.multilingualService
      .getPageData(this.page_id, this.section_id)
      .subscribe(
        (response) => {
          this.footer = response.data[0].page_data[0];
          // console.log('footer Data:', this.footer?.cli);
        },
        (error) => {
          console.error("Error fetching footer data", error);
        }
      );
  }
}
