import { Component, OnInit } from '@angular/core';
import { MultilingualService } from 'src/app/services/multilingual.service';

@Component({
  selector: 'app-home-demo-nine',
  templateUrl: './home-demo-nine.component.html',
  styleUrls: ['./home-demo-nine.component.scss']
})
export class HomeDemoNineComponent implements OnInit {
  page_id: string="home";
  section_id: string="p1";
  home: any={};
  isRtl: string = 'N';

  constructor(private multilingualService: MultilingualService) { }

  ngOnInit(): void {
    this.multilingualService.getIsRtl().subscribe(isRtl => {
      this.isRtl = isRtl;
    });
    this.multilingualService.getPageData(this.page_id, this.section_id).subscribe(
      (response)=>{
        this.home=response.data[0].page_data[0];
        // console.log('Home Data:', this.home);
      },
      (error)=>{
        console.error('Error fetching Home data', error);
      })
  }

}
