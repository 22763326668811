<header [dir]="isRtl === 'Y' ? 'rtl' : 'ltr'">
    <div class="container">
        <nav class="navigation" [ngClass]="{ sticky: isSticky }">
            <a class="navbar-brand" routerLink="/"><img [src]="headerMenu?.logo" alt="logo" /></a>
            <ul class="nav_menu" [class.active]="classApplied">
                <li class="nav_list" *ngIf="headerMenu?.home">
                    <a href="javascript:void(0)" [routerLink]="['']" routerLinkActive="router-link-active"
                        class="nav_link active">
                        <span>{{ headerMenu?.home.label }}</span>
                    </a>
                </li>
                <li class="nav_list" *ngIf="headerMenu?.about_us">
                    <a href="javascript:void(0)" [routerLink]="headerMenu?.about_us.routerLink" routerLinkActive="router-link-active"
                        class="nav_link active">
                        <span>{{ headerMenu?.about_us.label }}</span>
                    </a>
                </li>
                <li class="nav_list nav_list_menu" *ngIf="headerMenu?.with_us && headerMenu.with_us.submenu && headerMenu.with_us.submenu.length"(mouseenter)="openSubMenu(submenu)" (mouseleave)="closeSubMenu(submenu)">
                    <a href="javascript:void(0)" class="nav_link active">
                        <span>{{ headerMenu?.with_us.label }}</span>
                        <i class="ri-arrow-down-s-line arrow"></i>
                    </a>
                    <div class="dropdown simple-menu" #submenu>
                        <div class="simple-inner">
                            <div *ngFor="let item of headerMenu.with_us.submenu">
                                <div class="item-list" (click)="scrollToSection(headerMenu?.with_us.routerLink, item.routerLink);closeSubMenu(submenu); toggleClass(false)">
                                    <div class="item-img">
                                        <i class="{{ item.icon }}"></i>
                                    </div>
                                    <div class="item-list-info">
                                        <h4 class="nav_sub active">{{ item.label }}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="nav_list nav_list_menu" *ngIf="headerMenu?.products && headerMenu.products.submenu && headerMenu.products.submenu.length"(mouseenter)="openSubMenu(submenu)" (mouseleave)="closeSubMenu(submenu)">
                    <a href="javascript:void(0)" class="nav_link active">
                        <span>{{ headerMenu?.products.label }}</span>
                        <i class="ri-arrow-down-s-line arrow"></i>
                    </a>
                    <div class="dropdown" #submenu>
                        <div class="dropdown-inner">
                            <div class="dropdown-item" *ngFor="let section of headerMenu.products.submenu">
                                <h3 class="item-heading">{{ section.heading }}</h3>
                                <div *ngFor="let item of section.items">
                                    <div class="item-list" [routerLink]="item.routerLink" routerLinkActive="active" (click)="closeSubMenu(submenu); toggleClass(false)">
                                        <div class="item-img">
                                            <i class="{{ item.icon }}"></i>
                                        </div>
                                        <div class="item-list-info">
                                            <h4 class="nav_sub active">{{ item.label }}</h4>
                                            <p>{{ item.description }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="nav_list nav_list_menu" *ngIf="headerMenu?.solutions && headerMenu.solutions.submenu && headerMenu.solutions.submenu.length" (mouseenter)="openSubMenu(submenu)" (mouseleave)="closeSubMenu(submenu)">
                    <a href="javascript:void(0)" class="nav_link active">
                        <span>{{ headerMenu.solutions.label }}</span>
                        <i class="ri-arrow-down-s-line arrow"></i>
                    </a>
                    <div class="dropdown" #submenu>
                        <div class="dropdown-inner">
                            <div class="slns-menu">
                                <div *ngFor="let item of headerMenu.solutions.submenu">
                                    <div class="item-list" [routerLink]="item.routerLink" routerLinkActive="active" (click)="closeSubMenu(submenu); toggleClass(false)">
                                        <div class="item-img">
                                            <i class="{{ item.icon }}"></i>
                                        </div>
                                        <div class="item-list-info">
                                            <h4 class="nav_sub active">{{ item.label }}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="nav_list nav_list_menu" *ngIf="headerMenu?.contact_us && headerMenu.contact_us.submenu && headerMenu.contact_us.submenu.length"(mouseenter)="openSubMenu(submenu)" (mouseleave)="closeSubMenu(submenu)">
                    <a href="javascript:void(0)" class="nav_link active">
                        <span>{{headerMenu?.contact_us.label}}</span>
                        <i class="ri-arrow-down-s-line arrow"></i>
                    </a>
                    <div class="dropdown simple-menu" #submenu>
                        <div class="simple-inner">
                            <div *ngFor="let item of headerMenu.contact_us.submenu">
                                <div class="item-list" (click)="scrollToSection(headerMenu?.contact_us.routerLink, item.routerLink);closeSubMenu(submenu);toggleClass(false)">
                                    <div class="item-img">
                                        <i class="{{ item.icon }}"></i>
                                    </div>
                                    <div class="item-list-info">
                                        <h4 class="nav_sub active">{{ item.label }}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="nav_action">
                <a href="javascript:void(0)" class="btn" (click)="openModal()">
                    <img [src]="flag" alt="flag" width="30px">
                    <h4 class="nav_link lang">&nbsp;&nbsp;{{ langCode}}</h4>
                </a>
                <a routerLink="/demo-request" class="default-btn" *ngIf="headerMenu?.support_24x7">{{headerMenu?.support_24x7
                    }}</a>
                <div class="nav_toggle" (click)="toggleClass(true)" [class.active]="!classApplied">
                    <i class="ri-menu-line"></i>
                </div>
                <div class="nav_toggle" (click)="toggleClass(false)" [class.active]="classApplied">
                    <i class="ri-close-large-line"></i>
                </div>
            </div>
        </nav>
    </div>
</header>

<div *ngIf="add_flag=='Y'" class="container" [dir]="isRtl === 'Y' ? 'rtl' : 'ltr'">
    <div class="advert" [style.bottom]="calculateBottomPosition()">
        <img [src]="add.add_data[0].image_url" alt="flag" [style.height]="add?.add_data[0]?.height">
        <div class="advertText">
            <h3>{{add?.add_data[0]?.header_text}}</h3>
            <p>{{add?.add_data[0]?.detail_text}} <span><a [routerLink]="add?.add_data[0]?.url_link" routerLinkActive="router-link-active" class="read"><b>{{add?.add_data[0]?.read}}</b></a></span></p>
        </div>
        <span class="close-icon" (click)="closeAd()">
            <i class="ri-close-line"></i>
        </span>
    </div>
</div>

<!-- The Modal -->
<div class="modal" id="myModal" [dir]="isRtl === 'Y' ? 'rtl' : 'ltr'" *ngIf="headerMenu && headerMenu['country-lang']">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="country">
                <div class="modal-header">
                    <h3 class="modal-title">{{headerMenu['country-lang']}}</h3>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()"></button>
                </div>
                <div class="modal-body">
                    <ul *ngIf="langCountry && langCountry.length">
                        <li *ngFor="let country of langCountry; let last = last">
                            <div class="flag-name">
                                <img [src]="country.country_flag"
                                    alt="{{ country.country_name }}" width="30px" />
                                <h3>{{ country.country_name }}</h3>
                            </div>
                            <ul>
                                <li class="nav-list-lang" *ngFor="let lang of country.lng_data">
                                    <a (click)="updateUserPreferences(country.country_code, country.country_name, lang.lang_code, lang.lang_desc,lang.is_rtl); $event.preventDefault()">
                                        {{ lang.lang_desc }}
                                    </a>
                                </li>
                            </ul>
                            <div *ngIf="!last" class="separator"></div>
                        </li>
                    </ul>
                </div>
                <div class="modal-footer">
                    <button type="button" class="default-btn" data-bs-dismiss="modal"
                        (click)="closeModal()">{{headerMenu?.close}}</button>
                </div>
            </div>
        </div>
    </div>
</div>