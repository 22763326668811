<div class="features-area pb-100" [dir]="isRtl === 'Y' ? 'rtl' : 'ltr'">
    <div class="container">
        <div class="row product-signin-container" *ngIf="sign && sign.length">
            <div class="col-lg-3 col-md-6 col-sm-6 features-item with-border" *ngFor="let product of sign">
                <div class="features-card">
                    <div class="icon">
                        <!-- <img class="product-logo" [src]="'assets/img/num' + product.num + '-image.png'" alt="Product Image" /> -->
                        <img class="product-logo" src="assets/img/team/team1.jpg" alt="image">
                        <!-- <i class="ri-smartphone-line"></i> -->
                        <!-- <div class="number">{{ product.num }}</div> -->
                    </div>
                    <h3>{{ product.key }}</h3>
                    <p>{{ product.desc }}</p>
                </div>
                <div class="btn-box">
                    <a [routerLink]="product.signinLink" class="default-btn">{{signin}}</a>
                    <a [routerLink]="product.signupLink" class="default-btn2 default-btn">{{signup}}</a>
                </div>
            </div>
        </div>
    </div>
</div>