<div class="page-title-area page-title-style-two" [dir]="isRtl === 'Y' ? 'rtl' : 'ltr'">
    <div class="container">
        <div class="page-title-content">
            <h2>{{withUs?.heading}}</h2>
        </div>
    </div>
    <div class="divider"></div>
    <!-- <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div> -->
    <div class="banner-shape1"><img src="assets/img/shape/shape9.png" alt="image"></div>
</div>

<div class="about-area" [dir]="isRtl === 'Y' ? 'rtl' : 'ltr'" id="grow">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <!-- <span class="sub-title">{{about?.abt_l1}}</span> -->
                    <h2>{{withUs?.ourTeam_hd}}</h2>
                    <div class="features-text">
                        <h6>{{withUs?.ourTeam_hd2}}</h6>
                        <ng-container *ngFor="let item of withUs?.ourTeam">
                            <p>{{ item.label }}</p>
                        </ng-container>
                    </div>
                    <div class="btn-box">
                        <a routerLink="/demo-request" class="default-btn">{{withUs?.btn1}}</a>
                        <a (click)="scrollToFooterComponent()" class="link-btn">{{withUs?.btn2}}</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-img">
                    <img [src]="withUs?.ourTeam_img" data-aos="fade-up" alt="about">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="about-area pt-100" [dir]="isRtl === 'Y' ? 'rtl' : 'ltr'" id="team">
    <div class="container">
        <div class="row align-items-center text-image-reverser">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <!-- <span class="sub-title">{{about?.abt_l1}}</span> -->
                    <h2>{{ withUs?.grow_hd }}</h2>
                    <div class="features-text" *ngIf="withUs?.grow && withUs?.grow.length > 0">
                        <ng-container  *ngFor="let item of withUs?.grow">
                            <p>{{ item.label }}</p>
                        </ng-container>
                    </div>
                    <div class="btn-box">
                        <a routerLink="/demo-request" class="default-btn">{{withUs?.btn1}}</a>
                        <a (click)="scrollToFooterComponent()" class="link-btn">{{withUs?.btn2}}</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-img">
                    <img [src]="withUs?.grow_img" data-aos="fade-up" alt="about">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="about-area pt-100" [dir]="isRtl === 'Y' ? 'rtl' : 'ltr'" id="partners">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <h2>{{withUs?.ourPartners_hd}}</h2>
                    <p>{{withUs?.ourPartners_p}}</p>
                    <div class="features-text">
                        <h6>{{withUs?.ourPartners_q}}</h6>
                        <div *ngIf="withUs?.ourPartners && withUs?.ourPartners.length > 0">
                            <div *ngFor="let partner of withUs?.ourPartners; let last = last" class="vsn-item">
                                <p>
                                    {{ partner?.label }}
                                    <span *ngIf="last" class="bold-email"><b>{{ withUs?.email }}</b></span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="btn-box">
                        <a routerLink="/demo-request" class="default-btn">{{withUs?.btn1}}</a>
                        <a (click)="scrollToFooterComponent()" class="link-btn">{{withUs?.btn2}}</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-img">
                    <img [src]="withUs?.ourPartners_img" data-aos="fade-up" alt="about">
                </div>
            </div>
        </div>
    </div>
</div>
