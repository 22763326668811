import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MultilingualService } from 'src/app/services/multilingual.service';

@Component({
  selector: 'app-with-us',
  templateUrl: './with-us.component.html',
  styleUrl: './with-us.component.scss'
})
export class WithUsComponent implements OnInit {
  page_id: string="with-us";
  section_id: string="p1";
  withUs: any={}
  isRtl: string="N";

  @ViewChild('footerComponent') footerComponent!: ElementRef;

  constructor(private multilingualService: MultilingualService) {
   }

  ngOnInit(): void {
    this.multilingualService.getIsRtl().subscribe(isRtl => {
      this.isRtl = isRtl;
    });
    this.multilingualService.getPageData(this.page_id, this.section_id).subscribe(
      (response)=>{
        this.withUs=response.data[0].page_data[0];
        // console.log('withUs Data:', this.withUs);
      },
      (error)=>{
        console.error('Error fetching withUs data', error);
      })
  }

scrollToFooterComponent() {
    const footerComponent = document.getElementById('footerProductDetails');
    if (footerComponent) {
      footerComponent.scrollIntoView({ 
        behavior: 'smooth'});
    }
  }

}
