import { Component, HostListener, OnInit } from '@angular/core';
import { MultilingualService } from 'src/app/services/multilingual.service';

@Component({
  selector: 'app-navbar-style-five',
  templateUrl: './navbar-style-five.component.html',
  styleUrl: './navbar-style-five.component.scss'
})
export class NavbarStyleFiveComponent  implements OnInit {
  isRtl: string = 'N'; // Default to 'N'
  headerMenu: any = {};
  langCountry: any= {};

  constructor(private multilingualService: MultilingualService) {}

  ngOnInit(): void {
    // To change header and make RTL friendly start -- By AD✨
    this.multilingualService.getIsRtl().subscribe((isRtl) => {
      this.isRtl = isRtl;
    });
    this.multilingualService.getHeaderData().subscribe(
      (data) => {
        this.headerMenu = data?.data[0]?.menu_data[0];
      },
      (error) => {
        console.error('Error fetching header menu:', error);
      }
    );
    // To change header and make RTL friendly end -- By AD🌌

    // Fetching data for language/region start --By AD✨
    this.multilingualService.getLanguageData().subscribe(
      (response) => {
        this.langCountry = response?.data;
        // console.log(this.langCountry);
      },
      (error) => {
        console.error('Error fetching language and country options:', error);
      }
    )
    // Fetching data for language/region end --By AD✨
  }

  classApplied = false;
  toggleClass() {
    this.classApplied = !this.classApplied;
  }

  // Navbar Sticky
  isSticky: boolean = false;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (scrollPosition >= 50) {
      this.isSticky = true;
    } else {
      this.isSticky = false;
    }
  }

  switchLanguage(langCode: string, isRtl: string) {
    console.log('Switching to language:', langCode);
    console.log('Is RTL:', isRtl);
  }

  updateUserPreferences(
    countryCode: string, 
    countryName:string, 
    langCode: string,
    langDesc: string,
    isRtl: string){
      console.log(countryCode, countryName, langCode, langDesc, isRtl);
      this.multilingualService.updateUserPreferences(countryCode, countryName, langCode, langDesc, isRtl);
    }

}
