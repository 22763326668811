<div class="container pt-100" [dir]="isRtl === 'Y' ? 'rtl' : 'ltr'">
    <div class="col-lg-12 col-md-12">
        <div class="products-details-tabs">
            <h1 class="services-title pb-3">{{sec4_l1}}</h1>
            <ul class="nav-tabset">
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab1'}" *ngIf="sec4 && sec4[0]?.sec4_pnl1">
                    <span (click)="switchTab($event, 'tab1')">
                        {{sec4[0]?.sec4_pnl1}}
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab2'}" *ngIf="sec4 && sec4[0]?.sec4_pnl2">
                    <span (click)="switchTab($event, 'tab2')">
                        {{sec4[0]?.sec4_pnl2}}
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab3'}" *ngIf="sec4 && sec4[0]?.sec4_pnl3">
                    <span (click)="switchTab($event, 'tab3')">
                        {{sec4[0]?.sec4_pnl3}}
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab4'}" *ngIf="sec4 && sec4[0]?.sec4_pnl4">
                    <span (click)="switchTab($event, 'tab4')">
                        {{sec4[0]?.sec4_pnl4}}
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab5'}" *ngIf="sec4 && sec4[0]?.sec4_pnl5">
                    <span (click)="switchTab($event, 'tab5')">
                        {{sec4[0]?.sec4_pnl5}}
                    </span>
                </li>
            </ul>            
            <div class="tabs-container">
                <div class="features-area pt-100" *ngIf="currentTab === 'tab1' && sec4 && sec4[0] && sec4[0]?.sec4_sec1">
                    <div class="container">
                        <div class="row justify-content-space-evenly">
                            <div class="col-xl-4 col-lg-4 col-sm-6 col-md-4" *ngIf="sec4[0]?.sec4_sec1[0]">
                                <div class="features-box">
                                    <div class="icon bg2">
                                        <i class="ri-shake-hands-line"></i>
                                    </div>
                                    <h3>{{sec4[0]?.sec4_sec1[0]?.sec4_sec1_key1}}</h3>
                                    <p>{{sec4[0]?.sec4_sec1[0]?.sec4_sec1_desc1}}</p>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-sm-6 col-md-4" *ngIf="sec4[0]?.sec4_sec1[0]">
                                <div class="features-box">
                                    <div class="icon bg3">
                                        <i class="ri-briefcase-4-line"></i>
                                    </div>
                                    <h3>{{sec4[0]?.sec4_sec1[0]?.sec4_sec1_key2}}</h3>
                                    <p>{{sec4[0]?.sec4_sec1[0]?.sec4_sec1_desc2}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="features-area pt-100" *ngIf="currentTab === 'tab2' && sec4[0]?.sec4_sec2">
                    <div class="container">
                        <div class="row justify-content-space-evenly">
                            <div class="col-xl-4 col-lg-4 col-sm-6 col-md-4" *ngIf="sec4[0]?.sec4_sec2[0]">
                                <div class="features-box">
                                    <div class="icon">
                                        <i class="ri-bank-line"></i>
                                    </div>
                                    <h3>{{sec4[0]?.sec4_sec2[0]?.sec4_sec2_key1}}</h3>
                                    <p>{{sec4[0]?.sec4_sec2[0]?.sec4_sec2_desc1}}</p>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-sm-6 col-md-4" *ngIf="sec4[0]?.sec4_sec2[0]">
                                <div class="features-box">
                                    <div class="icon bg4">
                                        <i class="ri-funds-line"></i>
                                    </div>
                                    <h3>{{sec4[0]?.sec4_sec2[0]?.sec4_sec2_key2}}</h3>
                                    <p>{{sec4[0]?.sec4_sec2[0]?.sec4_sec2_desc2}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="features-area pt-100" *ngIf="currentTab === 'tab3' && sec4[0]?.sec4_sec3">
                    <div class="container">
                        <div class="row justify-content-space-evenly">
                            <div class="col-xl-4 col-lg-4 col-sm-6 col-md-4" *ngIf="sec4[0]?.sec4_sec3[0]">
                                <div class="features-box">
                                    <div class="icon bg2">
                                        <i class="ri-stack-line"></i>
                                    </div>
                                    <h3>{{sec4[0]?.sec4_sec3[0]?.sec4_sec3_key1}}</h3>
                                    <p>{{sec4[0]?.sec4_sec3[0]?.sec4_sec3_desc1}}</p>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-sm-6 col-md-4" *ngIf="sec4[0]?.sec4_sec3[0]">
                                <div class="features-box">
                                    <div class="icon bg3">
                                        <i class="ri-list-check-3"></i>
                                    </div>
                                    <h3>{{sec4[0]?.sec4_sec3[0]?.sec4_sec3_key2}}</h3>
                                    <p>{{sec4[0]?.sec4_sec3[0]?.sec4_sec3_desc2}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="features-area pt-100" *ngIf="currentTab === 'tab4'">
                    <div class="container">
                        <div class="row justify-content-space-evenly">
                            <div class="col-xl-4 col-lg-4 col-sm-6 col-md-4">
                                <div class="features-box">
                                    <div class="icon">
                                        <i class="ri-phone-line"></i>
                                    </div>
                                    <h3>{{sec4[0]?.sec4_sec4[0]?.sec4_sec4_key1}}</h3>
                                    <p>{{sec4[0]?.sec4_sec4[0]?.sec4_sec4_desc1}}</p>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-sm-6 col-md-4">
                                <div class="features-box">
                                    <div class="icon bg4">
                                        <i class="ri-bowl-line"></i>
                                    </div>
                                    <h3>{{sec4[0]?.sec4_sec4[0]?.sec4_sec4_key2}}</h3>
                                    <p>{{sec4[0]?.sec4_sec4[0]?.sec4_sec4_desc2}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="features-area pt-100" *ngIf="currentTab === 'tab5'">
                    <div class="container">
                        <div class="row justify-content-space-evenly">
                            <div class="col-xl-4 col-lg-4 col-sm-6 col-md-4" *ngIf="sec4[0]?.sec4_sec5 && sec4[0].sec4_sec5.length > 0">
                                <div class="features-box">
                                    <div class="icon bg2">
                                        <i class="ri-group-line"></i>
                                    </div>
                                    <h3>{{sec4[0]?.sec4_sec5[0]?.sec4_sec5_key1}}</h3>
                                    <p>{{sec4[0]?.sec4_sec5[0]?.sec4_sec5_desc1}}</p>
                                </div>
                            </div>
                            <!-- <div class="col-xl-4 col-lg-4 col-sm-6 col-md-4">
                                <div class="features-box">
                                    <div class="icon bg3">
                                        <i class="ri-fingerprint-line"></i>
                                    </div>
                                    <h3>{{sec4[0]?.sec4_sec1[0]?.sec4_sec1_key2}}</h3>
                                    <p>{{sec4[0]?.sec4_sec1[0]?.sec4_sec1_desc2}}</p>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    