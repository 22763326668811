import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrl: './products.component.scss'
})
export class ProductsComponent implements OnInit  {
  @Input() prod_l1: string = '';
  @Input() prod: any[] = [];
  @Input() btn1: string = '';
  @Input() read: string = '';
  @Input() isRtl: string = 'N';

  constructor() { }

  ngOnInit(): void {
  }
  currentTab = 'tab1';
  switchTab(event: MouseEvent, tab: string) {
    event.preventDefault();
    this.currentTab = tab;
  }

}
