import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-details-page-benefits',
  templateUrl: './details-page-benefits.component.html',
  styleUrl: './details-page-benefits.component.scss'
})
export class DetailsPageBenefitsComponent {
  @Input() ben_hd: string = '';
  @Input() ben: any[] = [];
  @Input() isRtl: string = 'N';
}
