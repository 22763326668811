<div class="contact-area" [dir]="isRtl === 'Y' ? 'rtl' : 'ltr'">
    <div class="container">
        <div class="form">
            <div class="section-title">
                <h2>{{demo?.demo_hd}}</h2>
                <p>{{demo?.demo_para}}</p>
            </div>
            <div class="contact-form">
                <form (ngSubmit)="submitForm(demoForm)" #demoForm="ngForm" class="demoRequestForm">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="form-group">
                                <label for="fullName">{{demo?.name_label}} *</label>
                                <input  type="text" class="form-control" id="fullName" name="fullName" [(ngModel)]="formData.fullName" required maxlength="100" placeholder="{{demo?.name}}">
                                <div *ngIf="demoForm && demoForm.controls['fullName'] && demoForm.controls['fullName'].invalid && (demoForm.controls['fullName'].dirty || demoForm.controls['fullName'].touched)">
                                    <div class="warning-message">{{demo?.name_err}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="form-group">
                                <label for="email">{{demo?.email_label}} *</label>
                                <input type="email" class="form-control" id="email" name="email" [(ngModel)]="formData.email" required [pattern]="emailPattern" placeholder="{{demo?.email}}" placeholder>
                                <div *ngIf="demoForm && demoForm.controls['email'] && demoForm.controls['email'].invalid && (demoForm.controls['email'].dirty || demoForm.controls['email'].touched)">
                                    <div class="warning-message">{{demo?.email_err}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="form-group">
                                <label for="company">{{demo?.hotel_label}} *</label>
                                <input type="text" class="form-control" id="company" name="company" [(ngModel)]="formData.company" required maxlength="100" placeholder="{{demo?.hotel}}">
                                <div *ngIf="demoForm && demoForm.controls['company'] && demoForm.controls['company'].invalid && (demoForm.controls['company'].dirty || demoForm.controls['company'].touched)">
                                    <div class="warning-message">{{demo?.hotel_err}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="form-group">
                                <label for="contactNo">{{demo?.contact_label}} *</label>
                                <input type="text" class="form-control" id="contactNo" name="contactNo" [(ngModel)]="formData.contactNo" required [pattern]="phonePattern" placeholder="{{demo?.contact}}">
                                <div *ngIf="demoForm && demoForm.controls['contactNo'] && demoForm.controls['contactNo'].invalid && (demoForm.controls['contactNo'].dirty || demoForm.controls['contactNo'].touched)">
                                    <div class="warning-message">{{demo?.contact_err}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="form-group">
                                <label for="noOfRooms">{{demo?.rooms_label}}</label>
                                <input type="text" class="form-control" id="noOfRooms" name="noOfRooms" [(ngModel)]="formData.noOfRooms" min="1" max="30" placeholder="{{demo?.rooms}}">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="form-group">
                                <label for="noOfPosUnits">{{demo?.pos_label}}</label>
                                <input type="text" class="form-control" id="noOfPosUnits" name="noOfPosUnits" [(ngModel)]="formData.noOfPosUnits" min="1" max="30" placeholder="{{demo?.pos}}">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="isAtLeastOneFieldInvalid()">
                            <div class="error-message form-group">
                                {{demo?.rooms_pos_err}}
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="form-group">
                                <label for="region">{{demo?.region}} *</label>
                                <select class="form-control" id="region" name="region" [(ngModel)]="formData.region" required *ngIf="countries && countries.length > 0">
                                    <option [ngValue]="curCountryCode" [selected]>{{demo?.curCountry}}</option>
                                    <option *ngFor="let country of countries" [ngValue]="country?.country_code">{{ country?.country_name }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <button type="submit" class="default-btn" [disabled]="demoForm.invalid" [ngClass]="{'banned-cursor': demoForm.invalid}">{{demo?.submit}}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>