import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-product-signin',
  templateUrl: './product-signin.component.html',
  styleUrl: './product-signin.component.scss'
})
export class ProductSigninComponent {
  @Input() sign_l1: string = '';
  @Input() sign: any[] = [];
  @Input() signin: string = '';
  @Input() signup: string = '';
  @Input() isRtl: string = 'N';
}
