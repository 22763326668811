import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-product-signin-two',
  templateUrl: './product-signin-two.component.html',
  styleUrl: './product-signin-two.component.scss'
})
export class ProductSigninTwoComponent {
  @Input() sign_l1: string = '';
  @Input() sign: any[] = [];
  @Input() signin: string = '';
  @Input() signup: string = '';
  @Input() isRtl: string = 'N';
}
