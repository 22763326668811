import { Component } from '@angular/core';
import { MultilingualService } from 'src/app/services/multilingual.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent {
  page_id: string="contact-us";
  section_id: string="p1";
  contact: any={}
  isRtl: string="N";
  show: boolean=true;

  constructor(private multilingualService: MultilingualService) { }

  ngOnInit(): void {
    this.multilingualService.getIsRtl().subscribe(isRtl => {
      this.isRtl = isRtl;
    });
    this.multilingualService.getPageData(this.page_id, this.section_id).subscribe(
      (response)=>{
        this.contact=response.data[0].page_data[0];
        // console.log('contact Data:', this.contact);
      },
      (error)=>{
        console.error('Error fetching contact data', error);
      })
  }
}
