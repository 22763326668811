import { Component } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MultilingualService } from "src/app/services/multilingual.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-demo-request",
  templateUrl: "./demo-request.component.html",
  styleUrls: ["./demo-request.component.scss"],
})
export class DemoRequestComponent {
  page_id: string = "demo-request";
  section_id: string = "p1";
  demo: any = {};
  isRtl: string = "N";

  formData: any = {}; // Object to store form data
  countries: any = {};
  curCountryCode: string = '';
  emailPattern: string = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$";
  phonePattern: string = "[0-9]{3}-?[0-9]{3}-?[0-9]{4}";

  constructor(private multilingualService: MultilingualService) {}

  ngOnInit(): void {
    this.multilingualService.getIsRtl().subscribe((isRtl) => {
      this.isRtl = isRtl;
    });
    this.multilingualService
      .getPageData(this.page_id, this.section_id)
      .subscribe(
        (response) => {
          this.demo = response.data[0].page_data[0];
          this.curCountryCode = response.data[0].country_code;
          this.formData.region = this.curCountryCode;
          // console.log('Current Country Code:', this.curCountryCode);
          this.multilingualService.getLanguageData().subscribe(
            (languageResponse) => {
              this.countries = languageResponse?.data;
              this.countries = this.countries.filter((country: { country_code: any; }) => country.country_code !== this.curCountryCode);
              // console.log(this.countries);
            },
            (error) => {
              console.error("Error fetching language and country options:", error);
            }
          );
        },
        (error) => {
          console.error("Error fetching demo data", error);
        }
      );
  }

  submitForm(form: NgForm) {
    if (this.formData.invalid || this.isAtLeastOneFieldInvalid()) {
      return;
    }
    // Submit form logic here
    // console.log("Form submitted:", this.formData);

    const formDataToSend = {
      full_name: this.formData.fullName,
      company: this.formData.company,
      no_of_room: this.formData.noOfRooms,
      no_of_pos_unit: this.formData.noOfPosUnits,
      email: this.formData.email,
      contact_no: this.formData.contactNo,
      region: this.formData.region,
      cr_datetime: new Date().toISOString(), // Example date formatting
      status: "A", // Example status
    };
    // console.log(formDataToSend);
    this.multilingualService.submitDemoRequest(formDataToSend).then(() => {
      // Show success message using Swal
      Swal.fire({
        icon: 'success',
        title: this.demo.success,
        text: this.demo.success_detail,
        confirmButtonText: 'OK',
      });
      form.resetForm({ region: this.formData.region });
    })
    .catch(error => {
      // Handle error if needed
      console.error('Error submitting form:', error);
      // Show error message using Swal
      Swal.fire({
        icon: 'error',
        title: this.demo.error,
        text: this.demo.error_detail,
        confirmButtonText: 'OK',
      });
    });;
    // this.multilingualService.saveUserMaster(formDataToSend);
  }

  isAtLeastOneFieldInvalid(): boolean {
    const noOfRooms = this.formData?.noOfRooms;
    const noOfPosUnits = this.formData?.noOfPosUnits;
    const numericRegex = /^[0-9]+$/;
    let isNoOfRoomsValid = 1;
    let isNoOfPosUnitsValid = 1;

    if (noOfRooms) {
      isNoOfRoomsValid =
        noOfRooms?.toString().match(numericRegex) &&
        parseInt(noOfRooms.toString()) >= 1 &&
        parseInt(noOfRooms.toString()) <= 30;
    }
    if (noOfPosUnits) {
      isNoOfPosUnitsValid =
        noOfPosUnits?.toString().match(numericRegex) &&
        parseInt(noOfPosUnits.toString()) >= 1 &&
        parseInt(noOfPosUnits.toString()) <= 30;
    }

    if (!noOfRooms && !noOfPosUnits) return true;

    return !(isNoOfRoomsValid && isNoOfPosUnitsValid);
  }
}
