<div class="banner-area pb-75" [dir]="isRtl === 'Y' ? 'rtl' : 'ltr'">
    <div class="main-banner">
      <div class="container">
        <div class="content">
          <span class="sub-title1">{{ heading }}</span>
          <span class="sub-title">{{ trial }}</span>
          <div class="scroller">
            <h1 *ngIf="bannerLoaded && banner.length > 0" [ngClass]="fadeInOut ? 'fade-in' : 'fade-out'">
              {{ banner[currentBannerIndex].label }}
            </h1>
          </div>
          <a routerLink="/demo-request" class="default-btn">{{ btn1 }}</a>
        </div>
      </div>
    </div>
  </div>
  
  <!-- <div class="screenshots-area bg-black-color pb-100" [dir]="isRtl === 'Y' ? 'rtl' : 'ltr'">
    <div class="container">
      <div class="screenshots-tabs">
        <ul class="nav-tabset">
          <li class="nav-tab" [ngClass]="{ active: currentTab === 'tab' + index }"
            *ngFor="let photo of banner_photos; let index = index">
            <span (click)="switchTab($event, 'tab' + index)">
              {{ photo.label }}
            </span>
          </li>
        </ul>
        <div class="tabs-container">
          <div class="pane" id="tab{{ index }}" *ngFor="let photo of banner_photos; let index = index"
            [ngClass]="{ active: currentTab === 'tab' + index }">
            <img *ngIf="currentTab === 'tab' + index" [src]="photo.link" alt="screenshots" loading="lazy" />
          </div>
        </div>
      </div>
    </div>
  </div> -->