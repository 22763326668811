<div class="new-app-main-banner-gradient-area" [dir]="isRtl === 'Y' ? 'rtl' : 'ltr'">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="new-app-main-banner-gradient-content">
                    <span class="sub-title">{{sec1_p1}}</span>
                    <div class="verticallyCenter">
                        <h1 *ngIf="bannerLoaded && banner.length > 0" class="bannerText" [ngClass]="fadeInOutText ? 'fade-in' : 'fade-out'">
                            {{ banner[currentBannerIndex].label }}
                        </h1>
                    </div>
                    <p>{{sec1_p2}}</p>
                    <div class="banner-btn">
                        <a routerLink="/demo-request" class="default-btn">{{btn1}}</a>
                    </div>
                </div>
            </div> 
            <div class="col-lg-6 col-md-12">
                <div class="new-app-main-banner-gradient-image">
                    <img *ngIf="banner_photos && banner_photos.length > 0" [ngClass]="fadeInOutImage ? 'fade-in' : 'fade-out'" [src]="getCurrentImageUrl()" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>
