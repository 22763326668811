<div class="features-area pb-75" [dir]="isRtl === 'Y' ? 'rtl' : 'ltr'">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="features-image text-center">
                    <img src="{{ currentImage ? currentImage : defaultImageM }}" alt="app-img" data-aos="fade-up">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="features-content">
                    <h2>{{ mod_hd }}</h2>
                    <ul class="features-list">
                        <li *ngFor="let item of mod" (mouseover)="showImage(item)">
                            <div class="icon">
                                <i class="{{ item.icon }}"></i>
                            </div>
                            <h3>{{ item.key }}</h3>
                            <p>{{ item.desc }}</p>
                        </li>
                    </ul>
                    <div class="btn-box">
                        <a routerLink="/demo-request" class="default-btn">{{btn1}}</a>
                        <a fragment="features" (click)="scrollTo('#features')"  class="link-btn">{{btn2}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-shape1"><img src="assets/img/shape/bg-shape1.png" alt="bg-shape"></div>
</div>