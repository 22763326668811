<div class="why-us"  [dir]="isRtl === 'Y' ? 'rtl' : 'ltr'">
    <div class="container pt-100">
        <div class="section-title">
            <span class="sub-title">{{why_q}}</span>
            <!-- <h2>{{why_q}}</h2> -->
            <h2>{{why_l1}}</h2>
            <p>{{why_p1}}</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6 col-md-6" *ngIf="why && why.length > 0">
                <div class="features-item with-border">
                    <div class="icon">
                        <i class="ri-lightbulb-flash-line"></i>
                    </div>
                    <h3>{{why[0]?.why_key1}}</h3>
                    <p>{{why[0]?.why_pnl1}}</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6" *ngIf="why && why.length > 0">
                <div class="features-item with-border">
                    <div class="icon bg2">
                        <i class="ri-group-2-line"></i>
                    </div>
                    <h3>{{why[0]?.why_key2}}</h3>
                    <p>{{why[0]?.why_pnl2}}</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6" *ngIf="why && why.length > 0">
                <div class="features-item with-border">
                    <div class="icon bg3">
                        <i class="ri-history-line"></i>
                    </div>
                    <h3>{{why[0]?.why_key3}}</h3>
                    <p>{{why[0]?.why_pnl3}}</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6" *ngIf="why && why.length > 0">
                <div class="features-item with-border">
                    <div class="icon bg4">
                        <i class="ri-medal-2-line"></i>
                    </div>
                    <h3>{{why[0]?.why_key4}}</h3>
                    <p>{{why[0]?.why_pnl4}}</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6" *ngIf="why && why.length > 0">
                <div class="features-item with-border">
                    <div class="icon">
                        <i class="ri-customer-service-2-line"></i>
                    </div>
                    <h3>{{why[0]?.why_key5}}</h3>
                    <p>{{why[0]?.why_pnl5}}</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6" *ngIf="why && why.length > 0">
                <div class="features-item with-border">
                    <div class="icon bg2">
                        <i class="ri-tools-line"></i>
                    </div>
                    <h3>{{why[0]?.why_key6}}</h3>
                    <p>{{why[0]?.why_pnl6}}</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6" *ngIf="why && why.length > 0">
                <div class="features-item with-border">
                    <div class="icon bg3">
                        <i class="ri-cpu-line"></i>
                    </div>
                    <h3>{{why[0]?.why_key7}}</h3>
                    <p>{{why[0]?.why_pnl7}}</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6" *ngIf="why && why.length > 0">
                <div class="features-item with-border">
                    <div class="icon bg4">
                        <i class="ri-article-line"></i>
                    </div>
                    <h3>{{why[0]?.why_key8}}</h3>
                    <p>{{why[0]?.why_pnl8}}</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6" *ngIf="why && why.length > 0">
                <div class="features-item with-border">
                    <div class="icon">
                        <i class="ri-leaf-line"></i>
                    </div>
                    <h3>{{why[0]?.why_key9}}</h3>
                    <p>{{why[0]?.why_pnl9}}</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6" *ngIf="why && why.length > 0">
                <div class="features-item with-border">
                    <div class="icon bg2">
                        <i class="ri-presentation-line"></i>
                    </div>
                    <h3>{{why[0]?.why_key10}}</h3>
                    <p>{{why[0]?.why_pnl10}}</p>
                </div>
            </div>
        </div>
        <div class="section-end">
            <p>{{why_p2}}</p>
        </div>
    </div>
</div>