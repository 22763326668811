import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-details-page-asc-services',
  templateUrl: './details-page-asc-services.component.html',
  styleUrl: './details-page-asc-services.component.scss'
})
export class DetailsPageAscServicesComponent implements OnInit {
  @Input() serv_hd: string = '';
  @Input() defaultImageS: string = '';
  @Input() btn1: string = '';
  @Input() btn2: string = '';
  @Input() serv: any[] = [];
  @Input() isRtl: string = 'N';

  constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const fragment = this.activatedRoute.snapshot.fragment;
      this.scrollTo(`#${fragment}`);
    });
  }

  currentImage: string = "";
  showImage(item: any) {
    this.currentImage = item.image;
  }

  scrollTo(elementId: string): void {
    const element = document.querySelector(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }
} {

}
