<div id="features" class="features-area pt-75 pb-75" [dir]="isRtl === 'Y' ? 'rtl' : 'ltr'">
    <div class="container">
        <div class="section-title">
            <h2>{{feat_hd}}</h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6 col-md-6" *ngFor="let feature of feat">
                <div class="features-item with-border">
                    <div class="icon">
                        <i [class]="feature.icon"></i>
                    </div>
                    <h3>{{ feature.key }}</h3>
                    <p>{{ feature.desc }}</p>
                </div>
            </div>
        </div>
    </div>
</div>