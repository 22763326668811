<app-homenine-banner
  [sec1_p1]="home?.sec1_p1"
  [banner]="home?.banner"
  [banner_photos]="home?.banner_photos"
  [sec1_p2]="home?.sec1_p2"
  [btn1]="home?.btn1"
  [isRtl]="isRtl"
></app-homenine-banner>

<!-- <div class="partner-area ptb-100">
    <div class="container">
        <div class="partner-title">
            Trusted by world famous companies:
        </div>
        <app-partner></app-partner>
    </div>
</div> -->

<app-homenine-advantages
  [sec2_l1]="home?.sec2_l1"
  [sec2]="home?.sec2"
  [isRtl]="isRtl"
></app-homenine-advantages>

<app-homenine-features
  [sec3_l1]="home?.sec3_l1"
  [sec3_desc]="home?.sec3_desc"
  [sec3]="home?.sec3"
  [btn1]="home?.btn1"
  [btn2]="home?.btn2"
  [isRtl]="isRtl"
></app-homenine-features>

<!-- <app-homenine-app-progress></app-homenine-app-progress> -->

<!-- <app-key-features></app-key-features> -->

<!-- <div class="app-screenshots-wrap-area ptb-100">
    <app-screenshots-style-two></app-screenshots-style-two>
</div> -->

<!-- <app-get-started></app-get-started> -->

<!-- <app-client-reviews-style-two></app-client-reviews-style-two> -->

<!-- <div class="pricing-area bg-gradient-color pt-100 pb-75">
    <app-pricing-style-one></app-pricing-style-one>
    <div class="shape7"><img src="assets/img/shape/shape6.png" alt="shape"></div>
    <div class="shape8"><img src="assets/img/shape/shape7.png" alt="shape"></div>
</div> -->

<!-- <app-homenine-blog></app-homenine-blog> -->

<!-- <app-free-trial-style-four></app-free-trial-style-four> -->