<div class="navbar-area" [ngClass]="{ sticky: isSticky }">
  <div class="container">
    <nav
      class="navbar navbar-expand-lg navbar-light bg-light"
      [class.active]="classApplied"
    >
      
      <a class="navbar-brand" routerLink="/"
        ><img src="assets/img/logo.png" alt="logo"
      /></a>
      <button class="navbar-toggler" type="button" (click)="toggleClass()">
        <span class="burger-menu">
          <span class="top-bar"></span>
          <span class="middle-bar"></span>
          <span class="bottom-bar"></span>
        </span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a href="javascript:void(0)" class="nav-link active">{{
              headerMenu?.home
            }}</a>
            <!-- <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Demo - 1</a></li>
                            <li class="nav-item"><a routerLink="/index-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Demo - 2</a></li>
                            <li class="nav-item"><a routerLink="/index-3" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Demo - 3</a></li>
                            <li class="nav-item"><a routerLink="/index-4" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Demo - 4</a></li>
                            <li class="nav-item"><a routerLink="/index-5" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Demo - 5</a></li>
                            <li class="nav-item"><a routerLink="/index-6" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Demo - 6</a></li>
                            <li class="nav-item"><a routerLink="/index-7" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Demo - 7</a></li>
                            <li class="nav-item"><a routerLink="/index-8" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Demo - 8</a></li>
                            <li class="nav-item"><a routerLink="/index-9" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Demo - 9</a></li>
                        </ul> -->
          </li>
          <li class="nav-item">
            <a href="javascript:void(0)" class="nav-link">{{
              headerMenu?.about_us
            }}</a>
            <!-- <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/about-simple" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Simple</a></li>
                            <li class="nav-item"><a routerLink="/about-modern" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Modern</a></li>
                            <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Features</a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/features-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Features 1</a></li>
                                    <li class="nav-item"><a routerLink="/features-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Features 2</a></li>
                                </ul>
                            </li>
                        </ul> -->
          </li>
          <!-- <li class="nav-item megamenu">
            <a href="javascript:void(0)" class="dropdown-toggle nav-link"
              >Pages</a
            >
            <ul class="dropdown-menu">
              <li class="nav-item">
                <div class="container">
                  <div class="row">
                    <div class="col">
                      <h6 class="submenu-title">Pages I</h6>
                      <ul class="megamenu-submenu">
                        <li>
                          <a
                            routerLink="/team-1"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            >Team 1</a
                          >
                        </li>
                        <li>
                          <a
                            routerLink="/team-2"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            >Team 2</a
                          >
                        </li>
                        <li>
                          <a
                            routerLink="/how-it-works"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            >How It Works</a
                          >
                        </li>
                        <li>
                          <a
                            routerLink="/gallery"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            >Gallery</a
                          >
                        </li>
                        <li>
                          <a
                            routerLink="/services"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            >Services</a
                          >
                        </li>
                        <li>
                          <a
                            routerLink="/pricing"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            >Pricing Plan</a
                          >
                        </li>
                      </ul>
                    </div>
                    <div class="col">
                      <h6 class="submenu-title">Pages II</h6>
                      <ul class="megamenu-submenu">
                        <li>
                          <a
                            routerLink="/feedback"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            >Reviews</a
                          >
                        </li>
                        <li>
                          <a
                            routerLink="/sign-in"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            >Sign In</a
                          >
                        </li>
                        <li>
                          <a
                            routerLink="/sign-up"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            >Sign Up</a
                          >
                        </li>
                        <li>
                          <a
                            routerLink="/forget-password"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            >Forget Password</a
                          >
                        </li>
                        <li>
                          <a
                            routerLink="/privacy-policy"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            >Privacy Policy</a
                          >
                        </li>
                        <li>
                          <a
                            routerLink="/terms-conditions"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            >Terms & Conditions</a
                          >
                        </li>
                      </ul>
                    </div>
                    <div class="col">
                      <h6 class="submenu-title">Pages III</h6>
                      <ul class="megamenu-submenu">
                        <li>
                          <a
                            routerLink="/screenshots"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            >Screenshots</a
                          >
                        </li>
                        <li>
                          <a
                            routerLink="/faq"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            >FAQ</a
                          >
                        </li>
                        <li>
                          <a
                            routerLink="/coming-soon"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            >Coming Soon</a
                          >
                        </li>
                        <li>
                          <a
                            routerLink="/error-404"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            >404 Error Page</a
                          >
                        </li>
                        <li>
                          <a
                            routerLink="/app-download"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            >App Download</a
                          >
                        </li>
                        <li>
                          <a
                            routerLink="/contact"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            >Contact Us</a
                          >
                        </li>
                      </ul>
                    </div>
                    <div class="col">
                      <a
                        routerLink="/contact"
                        target="_blank"
                        class="d-block p-0"
                      >
                        <img src="assets/img/navbar.jpg" alt="image" />
                      </a>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </li> -->
          <li class="nav-item"  *ngIf="headerMenu?.with_us_smenu && headerMenu.with_us_smenu.length > 0">
            <a href="javascript:void(0)" class="dropdown-toggle nav-link"
              >{{ headerMenu?.with_us }}</a
            >
            <ul class="dropdown-menu">
              <li class="nav-item">
                <a
                  routerLink="/products"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >{{ headerMenu?.with_us_smenu[0].our_team }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/cart"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >{{ headerMenu?.with_us_smenu[0].grow_with_us }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/checkout"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >{{ headerMenu?.with_us_smenu[0].partners }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/products-details"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >{{ headerMenu?.with_us_smenu[0].whysamasa }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/products-details"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >{{ headerMenu?.with_us_smenu[0].our_clients }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/products-details"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >{{ headerMenu?.with_us_smenu[0]['latest_blogs_&_news'] }}</a
                >
              </li>
            </ul>
          </li>
          <li class="nav-item"  *ngIf="headerMenu?.products_smenu && headerMenu.products_smenu.length > 0">
            <a href="javascript:void(0)" class="dropdown-toggle nav-link"
              >{{ headerMenu?.products }}</a
            >
            <ul class="dropdown-menu">
              <li class="nav-item">
                <a
                  [routerLink]="['/details-page', '123']"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >{{ headerMenu?.products_smenu[0].komsys_pms }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/cart"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >{{ headerMenu?.products_smenu[0].techsa_pos }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/checkout"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >{{ headerMenu?.products_smenu[0].accur_financials }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/products-details"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >{{ headerMenu?.products_smenu[0].innvion_inventory }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/products-details"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >{{ headerMenu?.products_smenu[0].hotel_connect_guest }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/products-details"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >{{ headerMenu?.products_smenu[0].hotel_manager }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/products-details"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >{{ headerMenu?.products_smenu[0].interfaces }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/products-details"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >{{ headerMenu?.products_smenu[0].my_workdesk }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/products-details"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >{{ headerMenu?.products_smenu[0].my_restaurants }}</a
                >
              </li>
            </ul>
          </li>
          <li class="nav-item" *ngIf="headerMenu?.solutions_smenu && headerMenu.solutions_smenu.length > 0">
            <a href="javascript:void(0)" class="dropdown-toggle nav-link"
              >{{ headerMenu?.solutions }}</a
            >
            <ul class="dropdown-menu">
              <li class="nav-item">
                <a
                  routerLink="/blog-grid"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >{{ headerMenu?.solutions_smenu[0].on_primise }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/blog-right-sidebar"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >{{ headerMenu?.solutions_smenu[0].cloud_solution }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/blog-left-sidebar"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >{{ headerMenu?.solutions_smenu[0].hospitality_solutions }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/blog-details"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >{{ headerMenu?.solutions_smenu[0].my_workdesk }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/blog-right-sidebar"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >{{ headerMenu?.solutions_smenu[0].intergrations }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/blog-left-sidebar"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >{{ headerMenu?.solutions_smenu[0].hotel_manager }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/blog-details"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >{{ headerMenu?.solutions_smenu[0].interfaces }}</a
                >
              </li>
            </ul>
          </li>
          <!-- <li class="nav-item">
            <a
              routerLink="/contact"
              class="nav-link"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              >{{ headerMenu?.services }}</a
            >
          </li> -->
          <li class="nav-item">
            <a href="javascript:void(0)" class="dropdown-toggle nav-link"
              >{{ headerMenu?.contact_us }}</a
            >
            <ul class="dropdown-menu">
              <li class="nav-item">
                <a
                  routerLink="/blog-right-sidebar"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  ></a
                >
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a href="javascript:void(0)" class="dropdown-toggle nav-link">Language</a>
            <ul class="dropdown-menu">
              <li class="nav-item">
                <a routerLink="/blog-right-sidebar" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"></a>
              </li>
            </ul>
          </li>

            <!-- <div style="clear: both;">
                <ul class="navbar-nav">
                <li class="nav-item">
                    <a href="javascript:void(0)" class="dropdown-toggle nav-link">Language</a>
                    <ul class="dropdown-menu">
                    <li class="nav-item">
                        <a routerLink="/blog-right-sidebar" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"></a>
                    </li>
                    </ul>
                </li>
                </ul>
            </div> -->
        </ul>
        <div class="others-option">
          <a routerLink="/contact" class="default-btn">{{ headerMenu?.support_24x7 }}</a>
        </div>
      </div>
    </nav>
  </div>
</div>
